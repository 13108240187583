import WebServiceWrapper from "./WebServiceWrapper";

export const HruService = {
  GetAllHRUDetails,
  SaveHRUDetails,
};

function   GetAllHRUDetails(patientId) {
  debugger
  return WebServiceWrapper.Get(`/hru/GetAllHruData?patientId=${patientId}`);
}

function SaveHRUDetails(payload) {
    debugger    
  return WebServiceWrapper.PostWithHeader("/hru/SaveHruData", payload);
}

