import React from "react";
import { SideMenuItems } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
const EligibilityCriteria = () => {
  const navigatePaths = {
    prevPage: "",
    currPage: "/EligibilityCriteria",
    nextPage: "/PatientScreening",
  };

  const SavePage = async () => {};
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage}  />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.EligibilityCriteria} />
          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>ELIGIBILITY CRITERIA </h4>
              </div>

              <div className="survey-introduction-section">
                <div className="survey-introduction-content">
                  <div className="instructions">
                    <div>
                      <p>
                        We will begin to collect information on your selected
                        patients.
                        <br />
                        <br /> The first set of questions will help confirm that
                        this patient meets the study eligibility criteria.{" "}
        
                          If a patient fails to meet all of the criteria below
                          you will restart this section with a new patient.  
                     
                         Please note that the patient does not have to be alive
                        at the time of this chart abstraction {" "}
               
                          in order to{" "}
                   
                        be included in the study. <br /> <br />
                        This study population will include patients with
                        relapsed or refractory (R/R) large B-cell lymphoma
                        (LBCL) treated with epcoritamab in the real-world (i.e.,
                        outside of clinical trial setting).
                      </p>
                    </div>
                  </div>
                  <div className="note-border-box">
                    <div className="important-note">
                      <div>
                        <h5>
                          Patients must meet <u>all</u> the following inclusion
                          criteria to be eligible for inclusion:
                        </h5>
                        <ul >
                          <li>Adult patients (≥18 years of age)</li>
                          <li>
                            Documented CD20+ mature B-cell neoplasm with the
                            following LBCL subtypes by World Health Organization
                            (WHO) classification 2016 or 2008, based
                            representative biopsy report as abstracted from the
                            patient medical chart and reviewed by clinicians:
                            <ul>
                              <li >
                                Diffuse Large B-Cell Lymphoma (DLBCL; de novo or
                                histologically transformed from all indolent
                                subtypes, including Richter’s transformation),
                                including “double-hit” or “triple-hit” DLBCL
                                with MYC and BCL2 and/or BCL6 translocations
                                (technically classified in WHO 2016 as
                                High-grade B-cell Lymphoma [HGBCL])
                              </li>
                              <li >HGBCL</li>
                              <li >
                                Other subtypes (e.g., Primary Mediastinal Large
                                B-cell Lymphoma [PMBCL], Grade 3b Follicular
                                Lymphoma (FL)]
                              </li>
                            </ul>
                          </li>
                          <li>
                            R/R disease
                            <ul>
                              <li >
                                Relapsed disease is defined as disease that has
                                recurred ≥6 months after completion of therapy
                              </li>
                              <li>
                                Refractory disease is defined as disease that
                                either progressed during therapy or progressed
                                &lt;6 months since completion of therapy
                              </li>
                            </ul>
                          </li>
                          <li>
                            Treated with epcoritamab outside of a clinical trial
                            setting
                          </li>
                        </ul>
                        <br />
                        <h5>Dates</h5>
                        <p>
                          Drop downs will be provided for you to enter dates. If
                          the exact date is unknown, please select “UNK” for the
                          unknown part of the date (day and/or month){" "}
                        </p>
                      </div>
                      <br />
                    </div>
                  </div>
                  <br />
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EligibilityCriteria;
