import React, { useContext, useState } from "react";
import ErrorField from "../Components/ErrorField";
import { IsEmailValid, isStringEmpty } from "../Helpers/Utilities";
import { ForgotPassword, UserLoginMessage } from "../Helpers/HelperText";
import { UserLoginService } from "../WebApiServices/UserLogin.service";
import { HTTPResponse, ToastMessageType } from "../Helpers/Enums";
import { AppContext } from "../Contexts/AppContextProvider";


const PasswordRecovery = () => {

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const { ShowToast } = useContext(AppContext);

  const HandleEmailChange = (e) => {
    setEmail(e.target.value)
    setEmailErr("")
  }

  const Validate = () => {
    let isValid = true;
    if (isStringEmpty(email)) {
      setEmailErr(UserLoginMessage.LoginEmailBlank);
      isValid = false
    } else {
      if (!IsEmailValid(email)) {
        setEmailErr(UserLoginMessage.LoginInvalidEmail);
        isValid = false
      }
    }
    return isValid;
  }
  const HandleReset = async () => {
    if (Validate()) { 
      const response = await UserLoginService.ForgotPassword(email);
      const { status } = response;
      switch (status) {
        case HTTPResponse.OK: {
          ShowToast(ForgotPassword.EmailSuccess, ToastMessageType.Success)
          break;
        }
        case HTTPResponse.BadRequest: {
          ShowToast(ForgotPassword.EmailNotExistent, ToastMessageType.Failed)
          break;
        }
        default: {
          ShowToast(ForgotPassword.ErrorOccurred, ToastMessageType.Failed)
          break;
        }
      }
    }
  }


  return (
    <div className="gradient-custom admin-login">
      <div className="container py-5 custom-container">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div
              className="card bg-white text-black"
              style={{ borderRadius: "1rem", minHeight: 100 }}
            >
              <div className="card-body p-4">
                <div className="mb-md-2 mt-md-2 pb-2">
                  <h3 className="fw-bold mb-4 text-uppercase text-center forgot-password-heading">
                    Forgot Password?
                  </h3>
                  <div className="form-outline form-white margin-bottom-36">
                    <input
                      type="email"
                      id="typeEmailX"
                      placeholder="Enter Email ID"
                      className="form-control form-control-lg placeholder-font-size"
                      value={email}
                      onChange={HandleEmailChange}
                    />
                    <ErrorField show={emailErr} message={emailErr} />
                  </div>
                  <div className="text-center mb-2">
                    <center>
                      <button
                        className="btn btn-outline-light btn-lg px-5 login-button"
                        type="submit"
                        onClick={HandleReset}
                      >
                        Reset
                      </button>{" "}
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;
