import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../../Components/PatientInformation";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import {
    PageDesignConstants,
    SideMenuItems,
    HTTPResponse,
    ToastMessageType,
    ToastMessages,
    DefaultDate,
} from "../../../Helpers/Enums";
import SideBarMenu from "../../../Components/SideBarMenu";
import { Dropdown } from "react-bootstrap";
import ErrorField from "../../../Components/ErrorField";
//import { CommonError_Msgs, demographicScreen_Msgs } from "../../../Helpers/HelperText";
import { AppContext } from "../../../Contexts/AppContextProvider";
import {
    AllowOnlyIntegers,
    GetDMUID,
    GetDisplayDate,
    GetLocalStorageData,
    GetLoggedInUserID,
    GetOrdinal,
    GetOrdinalInWord,
    IsAfterIndex,
    IsDateBefore,
    IsDateEmpty,
    IsDateValid,
    Is_DateBefore,
    ParseDate,
    SetDateState,
    ValueInRange,
    isStringEmpty,
} from "../../../Helpers/Utilities";
import DateControl from "../../../Components/DateControl";
import {
    CommonError_Msgs,
    CommonMsgs,
    Epcoritamab_Msgs,
} from "../../../Helpers/HelperText";
import { TreatmentResponseService } from "../../../WebApiServices/TreatmentResponse.service";

const Epcoritamab = () => {
    let patientData = GetLocalStorageData("patient");
    patientData = patientData && JSON.parse(patientData);
    // const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
    // const surveyDate = patientData?.surveyDate ?? "";
    const userId = GetLoggedInUserID() ?? 0;
    const pid = GetLocalStorageData("patientId") ?? 0;
    const patientId = GetLocalStorageData("patientId");

    const [index_date, setIndexDate] = useState("");
    const [chartAbstractionDate, setChartAbstractionDate] = useState("");
    const [death_Date, set_Death_Date] = useState("");

    // State to manage visibility of Q46 based on LYRIC or Lugano selection
    const [showQ46, setShowQ46] = useState([]);
    const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout, appState } =
        useContext(AppContext);
    
    // State to manage visibility of Q47 based on LYRIC or Lugano selection
    // const [showQ47, setShowQ47] = useState([]);

    const navigatePaths = {
        prevPage: "/Demographics",
        currPage: "/Epcoritamab",
        nextPage: appState.tmtPriorEpcoLOTCnt ?? 0 > 0 ? "/TreatmentsPriorEpcoritamab/1" : "/CARTCellTherapy"
    };

    const InitialSoftErrList = {
        q38Unk_err: false,
        q39Unk_err: false,
        q40Unk_err: false,
        q41Unk_err: false,
        q42Unk_err: false,
    };

    const InitialHardErrList = {
        q36Empty: false,
        q37Empty: false,
        q37EmptyagentTxt: false,
        q37EmptyOtherTxt: false,

        q38Empty: false,
        q39Empty: false,
    q38SizeOutOfRange:false,
    q39SizeOutOfRange:false,

        q40Empty: false,
        q41Empty: false,
        q42Empty: false,
    q42txtEmpty:false,
        q41_date_afterIndex: false,
        q41_date_beforeDeathorChart: false,
    };

    const EPCORAssesmentRow = {
        epcoritamabradiologicalassessmentid: 0, // pk of child table

        //Question 43
        radiological_assess_dx: null,
        radiological_assess_dx_date: DefaultDate,

        q43afterindexEmptyHard: false,
        q43beforeQ41dateEmptyHard: false,

        radiologyDxDateUnkSoft: false,
        radiologyDxEmptyHard: false,

        q44_LBCL_status: null,
        q44_lbcl_oth_txt: "",

        q44StatusUnkSoft: false,
        q44StatusEmptyHard: false,
        q44lbclTxtEmptyHard: false,

        //question 45
        q45_stage_status: null,
        q45statusEmptyHard: false,

        //question 46
        q46LyricAssess: null,
        q46LyricEmptyHard: false,

        //question 47
        q47_radiology_type: null,
        q47RadioAssessTypeOtherTxt: "",
        q47radiologyTypeUnkSoft: false,
        q47radiologyTypeEmptyHard: false,
        q47radiologyTypeTxtEmptyHard: false,
    };
    const [epcorAssessmentsDetails, setEPCORAssessmentsDetails] = useState([
        EPCORAssesmentRow,
    ]);

    //Q43-Q48 EPCOR Assessment Repeater
    const DeleteEPCORAssessmentRow = (id) => {
        const newArray = [...epcorAssessmentsDetails];
        newArray.splice(id, 1);
        setEPCORAssessmentsDetails(newArray);
    };

    const AddEPCORAssessmentRow = () => {
        setEPCORAssessmentsDetails((prev) => [
            ...prev,
            {
                ...EPCORAssesmentRow,
            },
        ]);
    };

    //Repeater
    const HandleRepeaterDateChange = (name, date, datePart) => {
        debugger;
        const lastIndex = name.lastIndexOf("-");
        const id = name.slice(lastIndex + 1);
        switch (name) {
            case "radiological_dx_date-" + id:
                {
                    let updatedArray = [...epcorAssessmentsDetails];
                    const idx = name.slice(lastIndex + 1);
                    updatedArray[id].radiological_assess_dx_date = SetDateState(
                        updatedArray[id].radiological_assess_dx_date,
                        date,
                        datePart
                    );
                    updatedArray[id].radiological_assess_dx = 1;

                    updatedArray[id].radiologyDxEmptyHard = false;
                    updatedArray[id].radiologyDxDateUnkSoft = false;
                    setEPCORAssessmentsDetails(updatedArray);
                }
                break;
            default:
                break;
        }
    };

    const HandleRepeaterFieldChange = (e) => {
        debugger;
        const { name, id, value } = e.target;
        const value_int = Number(value);

        switch (name) {
            //43
            case "radiological_assess_dx" + id: {
                let updatedArray = [...epcorAssessmentsDetails];
                updatedArray[id].radiological_assess_dx = value_int;
                updatedArray[id].radiological_assess_dx_date = DefaultDate;

                updatedArray[id].radiologyDxDateUnkSoft = value_int === 99;
                updatedArray[id].radiologyDxEmptyHard = false;

                updatedArray[id].q43afterindexEmptyHard = false;
                updatedArray[id].q43beforeQ41dateEmptyHard = false;


                setEPCORAssessmentsDetails(updatedArray);
                break;
            }

            //44
            case "q44_LBCL_status" + id: {
                let updatedArray = [...epcorAssessmentsDetails];
                updatedArray[id].q44_LBCL_status = value_int;
                updatedArray[id].q44_lbcl_oth_txt = "";

                updatedArray[id].q44StatusUnkSoft = value_int === 99;
                updatedArray[id].q44StatusEmptyHard = false;
        updatedArray[id].q44lbclTxtEmptyHard =false;
                setEPCORAssessmentsDetails(updatedArray);
                break;
            }

            case "q44_lbcl_oth_txt": {
                let updatedArray = [...epcorAssessmentsDetails];
                updatedArray[id].q44_LBCL_status = 5;
                updatedArray[id].q44_lbcl_oth_txt = value;

                updatedArray[id].q44StatusUnkSoft = value_int === 99;
                updatedArray[id].q44StatusEmptyHard = false;
                updatedArray[id].q44lbclTxtEmptyHard = false;
                setEPCORAssessmentsDetails(updatedArray);
                break;
            }
            //45
            case "q45_stage_status" + id: {
                let updatedArray = [...epcorAssessmentsDetails];

                // Reset dependent fields when q45_stage_status changes
                if (value_int === 1 || value_int === 3) {
                    updatedArray[id].q46LyricAssess = null;
                    // updatedArray[id].q47_radiology_type = null;
                    // updatedArray[id].q47RadioAssessTypeOtherTxt = "";

                    setShowQ46((prev) => ({ ...prev, [id]: true }));
                    // setShowQ47((prev) => ({ ...prev, [id]: false }));
                } else if (value_int === 2 || value_int === 4) {
                    updatedArray[id].q46LyricAssess = null;
                    // updatedArray[id].q47_radiology_type = null;
                    // updatedArray[id].q47RadioAssessTypeOtherTxt = "";

                    setShowQ46((prev) => ({ ...prev, [id]: false }));
                    // setShowQ47((prev) => ({ ...prev, [id]: true }));
                }

                updatedArray[id].q45_stage_status = value_int;

                updatedArray[id].q45statusEmptyHard = false;
                updatedArray[id].q46LyricEmptyHard =false;
                setEPCORAssessmentsDetails(updatedArray);

                // Determine visibility for Q46 and Q47 based on q45_stage_status value
                if (value_int === 1 || value_int === 3) {
                    setShowQ46((prev) => ({ ...prev, [id]: true }));
                    //  setShowQ47((prev) => ({ ...prev, [id]: false }));
                } else if (value_int === 2 || value_int === 4) {
                    setShowQ46((prev) => ({ ...prev, [id]: false }));
                    //  setShowQ47((prev) => ({ ...prev, [id]: true }));
                }

                break;
            }

            //46
            case "q46LyricAssess" + id: {
                let updatedArray = [...epcorAssessmentsDetails];
                updatedArray[id].q46LyricAssess = value_int;

                updatedArray[id].q46LyricEmptyHard = false;
               
                setEPCORAssessmentsDetails(updatedArray);
                break;
            }

            //47
            case "q47_radiology_type" + id: {
                let updatedArray = [...epcorAssessmentsDetails];
                updatedArray[id].q47_radiology_type = value_int;
                updatedArray[id].q47RadioAssessTypeOtherTxt = "";

                updatedArray[id].q47radiologyTypeUnkSoft = value_int === 99;
                updatedArray[id].q47radiologyTypeEmptyHard = false;
        updatedArray[id].q47radiologyTypeTxtEmptyHard=false;
                setEPCORAssessmentsDetails(updatedArray);
                break;
            }

            case "q47RadioAssessTypeOtherTxt": {
                let updatedArray = [...epcorAssessmentsDetails];
                updatedArray[id].q47_radiology_type = 5;
                updatedArray[id].q47RadioAssessTypeOtherTxt = value;

                updatedArray[id].q47radiologyTypeUnkSoft = value_int === 99;
                updatedArray[id].q47radiologyTypeEmptyHard = false;
                updatedArray[id].q47radiologyTypeTxtEmptyHard = false;
                setEPCORAssessmentsDetails(updatedArray);
                break;
            }

            default:
                break;
        }
    };

    const [softErrList, setSoftErrList] = useState(InitialSoftErrList);
    const [hardErrList, setHardErrList] = useState(InitialHardErrList);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);


    const [epcoritamabDetails, setepcoritamabDetails] = useState({
        selectedLOT: null,
        q36_state: null,
        q37Mono: null,
        q37Lena: false,
        q37Lonca: false,
        q37Ritux: false,
        q37Vene: false,
        q37InvestAgent: false,
        q37InvestAgent_txt: "",
        q37Other: false,
        q37Other_txt: "",
        q38step_up: null,
        q38_days_txt: "",
        q38Unk: null,
        q39step_up: null,
        q39_days_txt: "",
        q40Discont: null,
        q41EpDiscontDtUnk: null,
        dt_discont: DefaultDate,
        q42DiscontReason: null,
        q42DiscontReasonOtherTxt: "",
    });

    const ValidateForm = () => {
        debugger;
        let isValid = true;
        //q36

        if (epcoritamabDetails.q36_state === null) {
            setHardErrList((prev) => ({
                ...prev,
                q36Empty: true,
            }));
            isValid = false;
        } else if (
            epcoritamabDetails.q36_state === 1 &&
            epcoritamabDetails.selectedLOT === null
        ) {
            setHardErrList((prev) => ({
                ...prev,
                q36Empty: true,
            }));
            isValid = false;
        } else {
            setHardErrList((prev) => ({
                ...prev,
                q36Empty: false,
            }));
        }

        // Validation for q37
        const {
            q37Lena,
            q37Lonca,
            q37Vene,
            q37Ritux,
            q37InvestAgent,
            q37Other,
            q37InvestAgent_txt,
            q37Other_txt,
            q37Mono,
        } = epcoritamabDetails;

        if (
            !q37Lena &&
            !q37Lonca &&
            !q37Vene &&
            !q37Ritux &&
            !q37InvestAgent &&
            !q37Other &&
            q37Mono !== 1
        ) {
            setHardErrList((prev) => ({
                ...prev,
                q37Empty: true,
            }));
            isValid = false;
        } else {
            setHardErrList((prev) => ({
                ...prev,
                q37Empty: false,
            }));
        }

        if (q37Other && q37Other_txt.trim() === "") {
            setHardErrList((prev) => ({
                ...prev,
                q37EmptyOtherTxt: true,
            }));
            isValid = false;
        }
        if (q37InvestAgent && q37InvestAgent_txt.trim() === "") {
            setHardErrList((prev) => ({
                ...prev,
                q37EmptyagentTxt: true,
            }));
            isValid = false;
        }

        //Question 38 validation
        if (epcoritamabDetails.q38step_up === null) {
            setHardErrList((prev) => ({
                ...prev,
                q38Empty: true,
            }));
            isValid = false;
        }



        else if (epcoritamabDetails.q38step_up === 1) {
            if (epcoritamabDetails.q38_days_txt === "") {
                setHardErrList((prev) => ({
                    ...prev,
                    q38Empty: true,
                }));
                isValid = false;
            } else if (!ValueInRange(parseInt(epcoritamabDetails.q38_days_txt), 1, 30)) {
                setHardErrList((prev) => ({
                    ...prev,
                    q38SizeOutOfRange: true,
                }));
                isValid = false;
            }
        }


        // else {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q38Empty: false,
        //   }));
        // }

        // question 39
        debugger
        if (epcoritamabDetails.q39step_up === null) {
            setHardErrList((prev) => ({
                ...prev,
                q39Empty: true,
            }));
            isValid = false;
        }
        // else if (
        //   epcoritamabDetails.q39step_up === 1 &&
        //   epcoritamabDetails.q39_days_txt === ""
        // ) {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q39Empty: true,
        //   }));
        //   isValid = false;
        // } 

        if (epcoritamabDetails.q39step_up === 1) {
            if (epcoritamabDetails.q39_days_txt === "") {
                setHardErrList((prev) => ({
                    ...prev,
                    q39Empty: true,
                }));
                isValid = false;
            } else if (!ValueInRange(parseInt(epcoritamabDetails.q39_days_txt), 1, 30)) {
                setHardErrList((prev) => ({
                    ...prev,
                    q39SizeOutOfRange: true,
                }));
                isValid = false;
            }
        }
        // else {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q39Empty: false,
        //   }));
        // }

        //question 40 validate

        if (epcoritamabDetails.q40Discont === null) {
            setHardErrList((prev) => ({
                ...prev,
                q40Empty: true,
            }));
            isValid = false;
        } else if (
            epcoritamabDetails.q40Discont === 0 ||
            epcoritamabDetails.q40Discont === 99
        ) {
            setHardErrList((prev) => ({
                ...prev,
                q41Empty: false,
                q42Empty: false,
            }));
        } else {
            setHardErrList((prev) => ({
                ...prev,
                q40Empty: false,
            }));
        }

        //validation for 41
        debugger
        if (
            epcoritamabDetails.q40Discont === 1 &&
            epcoritamabDetails.q41EpDiscontDtUnk === null
        ) {
            setHardErrList((prev) => ({
                ...prev,
                q41Empty: true,
            }));
            isValid = false;
        } 

        
        if (
            epcoritamabDetails.q40Discont === 1 &&
             epcoritamabDetails.dt_discont 
        ) {
            if (
                !IsDateEmpty(epcoritamabDetails.dt_discont) && IsDateValid(epcoritamabDetails.dt_discont) &&
                (!IsDateEmpty(chartAbstractionDate) ||
                    !IsDateEmpty(death_Date)) &&

                  
                !IsDateBefore(
                    epcoritamabDetails.dt_discont,
                    chartAbstractionDate,
                    death_Date
                )
            ) {
                setHardErrList((prev) => ({
                    ...prev,
                    q41Empty: false,
                    q41_date_beforeDeathorChart: true,
                }));
                isValid = false;
            }
        }
         else if (
            epcoritamabDetails.q40Discont === 1 &&
            epcoritamabDetails.dt_discont === DefaultDate
        ) {
            setHardErrList((prev) => ({
                ...prev,
                q41Empty: true,
            }));
            isValid = false;
        } 
        
                
      if (epcoritamabDetails.q40Discont === 1 &&  epcoritamabDetails.dt_discont )
        {
            if(
            !IsDateEmpty(epcoritamabDetails.dt_discont) && IsDateValid(epcoritamabDetails.dt_discont) &&  !IsDateEmpty(index_date) &&
            !IsAfterIndex(epcoritamabDetails.dt_discont,index_date) ){

                setHardErrList((prev) => ({
                    ...prev,
                    q41Empty: false,
                    q41_date_afterIndex: true,
                }));
                isValid = false;
            }
        }    
        
        // else {
        //     setHardErrList((prev) => ({
        //         ...prev,
        //         q41Empty: false,
        //     }));
        // }

        //question 42 //q42DiscontReasonOtherTxt
        if (
            epcoritamabDetails.q40Discont === 1 &&
            epcoritamabDetails.q42DiscontReason === null
        ) {
            setHardErrList((prev) => ({
                ...prev,
                q42Empty: true,
            }));
            isValid = false;
        } else if (
            epcoritamabDetails.q40Discont === 1 &&
            epcoritamabDetails.q42DiscontReason === 10 &&
            epcoritamabDetails.q42DiscontReasonOtherTxt === ""
        ) {
            setHardErrList((prev) => ({
                ...prev,
        q42txtEmpty: true,
            }));
            isValid = false;
        } else {
            setHardErrList((prev) => ({
                ...prev,
        q42txtEmpty: false,
            }));
        }

        //repeater
        if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
            const updatedArray = epcorAssessmentsDetails.map((item) => {
                let radiologyDxEmptyHard = false;
                if (
                    item.radiological_assess_dx == null ||
                    (item.radiological_assess_dx === 1 &&
                        IsDateEmpty(item.radiological_assess_dx_date))
                ) {
                    radiologyDxEmptyHard = true;
                }

                let q43afterindexEmptyHard = false;
                if(IsDateValid(item.radiological_assess_dx_date) && IsDateValid(index_date) ){
                    q43afterindexEmptyHard=
                    !IsDateEmpty(item.radiological_assess_dx_date) &&
                    !IsDateEmpty(index_date) &&
                    !IsAfterIndex(item.radiological_assess_dx_date, index_date);
                }

                let q43beforeQ41dateEmptyHard = false;
                if(IsDateValid(item.radiological_assess_dx_date) && IsDateValid(epcoritamabDetails.dt_discont) ){

                    q43beforeQ41dateEmptyHard=
                    !IsDateEmpty(item.radiological_assess_dx_date) &&
                    !IsDateEmpty(epcoritamabDetails.dt_discont) &&
                    !Is_DateBefore(
                        item.radiological_assess_dx_date,
                        epcoritamabDetails.dt_discont
                    );
                    }
                const q44StatusEmptyHard = item.q44_LBCL_status == null;
                const q44lbclTxtEmptyHard = q44StatusEmptyHard
                    ? false
                    : item.q44_LBCL_status === 5 && isStringEmpty(item.q44_lbcl_oth_txt);

                const q45statusEmptyHard = item.q45_stage_status == null;

                const q46LyricEmptyHard =
                    (item.q45_stage_status === 1 || item.q45_stage_status === 3) &&
                        item.q46LyricAssess === null
                        ? true
                        : false;

                const q47radiologyTypeEmptyHard =
                    // (item.q45_stage_status === 2 || item.q45_stage_status === 4) &&
                    item.q47_radiology_type == null
                        ? true
                        : false;
                const q47radiologyTypeTxtEmptyHard = q47radiologyTypeEmptyHard
                    ? false
                    : item.q47_radiology_type === 5 &&
                    isStringEmpty(item.q47RadioAssessTypeOtherTxt);

                if (
                    radiologyDxEmptyHard ||
                    (item.radiological_assess_dx == 1 &&
                        !IsDateValid(item.radiological_assess_dx_date)) ||
                    q44StatusEmptyHard ||
                    q44lbclTxtEmptyHard ||
                    q45statusEmptyHard ||
                    q46LyricEmptyHard ||
                    q47radiologyTypeEmptyHard ||
                    q47radiologyTypeTxtEmptyHard ||
                    q43afterindexEmptyHard ||
                    q43beforeQ41dateEmptyHard
                ) {
                    isValid = false;
                }

                return {
                    ...item,
                    radiologyDxDateUnkSoft: false,
                    radiologyDxEmptyHard,

                    q44StatusUnkSoft: false,
                    q44StatusEmptyHard,
                    q44lbclTxtEmptyHard,
                    q45statusEmptyHard,

                    q46LyricEmptyHard,

                    q47radiologyTypeUnkSoft: false,
                    q47radiologyTypeEmptyHard,
                    q47radiologyTypeTxtEmptyHard,

                    q43afterindexEmptyHard,
                    q43beforeQ41dateEmptyHard,
                };
            });
            setEPCORAssessmentsDetails(updatedArray);
        }

        return isValid;
    };

    const HandleFieldChange = (e) => {
        const { name, id, value, checked } = e.target;
        debugger;
        switch (name) {
            case "q36_state": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: parseInt(value), //value,
                    // q36_other_txt:""
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q37Empty: false,
                    // q37EmptyagentTxt: false,
                    // q37EmptyOtherTxt:false,
                }));
                break;
            }

            case "selectedLOT": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: Number(value),
                    q36_state: 1,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q36Empty: false,
                }));
                break;
            }
            // case "q36_other_txt": {
            //   setepcoritamabDetails((prev) => ({
            //     ...prev,
            //     [name]: value,
            //     q36_state: 2,
            //   }));

            //   break;
            // }

            case "q37Mono": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: Number(value), //value,
                    q37Lena: false,
                    q37Lonca: false,
                    q37Vene: false,
                    q37Ritux: false,
                    q37InvestAgent: false,
                    q37Other: false,
                    q37InvestAgent_txt: "",
                    q37Other_txt: "",
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q37Empty: false,
                    q37EmptyagentTxt: false,
                    q37EmptyOtherTxt: false,
                }));

                break;
            }

            case "q37Lena":
            case "q37Lonca":
            case "q37Vene":
            case "q37Ritux":
            case "q37InvestAgent":
            case "q37Other":
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: !prev[name],
                    ...(name === "q37Other" && { q37Other_txt: "" }),
                    ...(name === "q37InvestAgent" && { q37InvestAgent_txt: "" }),
                    q37Mono: false,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q37Empty: false,
                    q37EmptyagentTxt: false,
                    q37EmptyOtherTxt: false,
                }));

                break;

            case "q37InvestAgent_txt": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q37InvestAgent: true,
                    q37Mono: false,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q37Empty: false,
                    q37EmptyagentTxt: false,
                    q37EmptyOtherTxt: false,
                }));

                break;
            }

            case "q37Other_txt": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q37Other: true,
                    q37Mono: false,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q37Empty: false,
                    q37EmptyOtherTxt: false,
                    q37EmptyagentTxt: false,
                }));

                break;
            }

            case "q38step_up": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: Number(value), //value,
                    q38_days_txt: "",
                }));

                // if(Number(value) === 99){
                // }

                setSoftErrList((prev) => ({
                    ...prev,
                    q38Unk_err: Number(value) === 99,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q38Empty: false,
                    q38SizeOutOfRange:false,
                }));

                break;
            }



            case "q38_days_txt": {
                debugger
        if (value !== "" && !AllowOnlyIntegers(value) ) {
                    break;
                }
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q38step_up: 1,
                }));

                setSoftErrList((prev) => ({
                    ...prev,
                    q38Unk_err: false,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q38Empty: false,
                    q38SizeOutOfRange:false,
                }));

                break;
            }

            case "q39step_up": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: parseInt(value), //value,
                    q39_days_txt: "",
                }));

                setSoftErrList((prev) => ({
                    ...prev,
                    q39Unk_err: Number(value) === 99,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q39Empty: false,
                    q39SizeOutOfRange:false,
                }));

                break;
            }

            case "q39_days_txt": {
                if (value !== "" && !AllowOnlyIntegers(value)) {
                    break;
                }
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q39step_up: 1,
                }));

                setSoftErrList((prev) => ({
                    ...prev,
                    q39Unk_err: false,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q39Empty: false,
                    q39SizeOutOfRange:false,
                }));

                break;
            }

            case "q40Discont": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: Number(value), //value,
                    q42DiscontReason: null,
                    q41EpDiscontDtUnk: null,
                    dt_discont: DefaultDate,
          q42DiscontReasonOtherTxt:"",

                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q40Unk_err: Number(value) === 99,
          q41Unk_err:false,
          q42Unk_err:false,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q40Empty: false,
                    q41Empty:false,
                    q42Empty:false,
                    q42txtEmpty:false,
                }));
                break;
            }

            // case "dt_discont":{

            // }

            case "q41EpDiscontDtUnk": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: Number(value),
                    dt_discont: DefaultDate, //reset q8 date
                }));

                setSoftErrList((prev) => ({
                    ...prev,
                    q41Unk_err: Number(value) === 99,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q41Empty: false,
                    q41_date_afterIndex:false,
                    q41_date_beforeDeathorChart:false,
                }));
                break;
            }

            //q42

            case "q42DiscontReason": {
                const value_int = Number(value);
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: value_int,
                    q42DiscontReasonOtherTxt: "",
                }));

                setSoftErrList((prev) => ({
                    ...prev,
                    q42Unk_err: Number(value) === 99,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q42Empty: false,
                    q42txtEmpty:false,
                }));

                // if(value_int === 99)
                // setIsEligible(false);
                break;
            }
            case "q42DiscontReasonOtherTxt": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    [name]: value,
                    q42DiscontReason: 10,
                }));
                setSoftErrList((prev) => ({
                    ...prev,
                    q42Unk_err: false,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q42Empty: false,
          q42txtEmpty: false,
                }));

                break;
            }

            default:
                break;
        }
        // Hide top error message after an option is selected
        setShowTopErrMsg(false);
    };

    const SavePage = async (validate) => {
        try {
            debugger;
            ToggleLoader(true);
            setSoftErrList(InitialSoftErrList);
            setHardErrList(InitialHardErrList);

            let isValid = validate ? ValidateForm() : true;

            // if (!ValidateForm()) {
            //     setShowTopErrMsg(true);
            //     ToggleLoader(false);
            //     return false;
            // }
            // setShowTopErrMsg(false);

            if(!IsDateEmpty(epcoritamabDetails.dt_discont) && !IsDateValid(epcoritamabDetails.dt_discont))
            {
              isValid = false;
            }


                  if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
                    epcorAssessmentsDetails.map(item => {
          if (!IsDateEmpty(item.radiological_assess_dx_date) && !IsDateValid(item.radiological_assess_dx_date)) {
            isValid = false;
          } 
          return item; 
        });
        
      }

            if (isValid) {
                setShowTopErrMsg(false)
            const epcoritamabModel = {
                patientId: pid,

                //question 36
                epcorLot:
                    epcoritamabDetails.q36_state === 1
                        ? epcoritamabDetails.selectedLOT
                        : null,

                //question 37 payload
                receivedMonotherapyTreatment: epcoritamabDetails.q37Mono,
                otherTreatmentLenalidomide: epcoritamabDetails.q37Lena,
                otherTreatmentLoncastuximab: epcoritamabDetails.q37Lonca,
                otherTreatmentRituximab: epcoritamabDetails.q37Ritux,
                otherTreatmentVenetoclax: epcoritamabDetails.q37Vene,
                OtherTreatmentInvestigationalagent: epcoritamabDetails.q37InvestAgent,
                otherTreatmentInvestigationalagentTxt:
                    epcoritamabDetails.q37InvestAgent_txt,
                OtherTreatmentOth: epcoritamabDetails.q37Other,
                OtherTreatmentOthTxt: epcoritamabDetails.q37Other_txt,

                //question 38 payload
                dose1and2DaysDifference: epcoritamabDetails.q38_days_txt,
                dose1and2DaysDifferenceUnk:
                    epcoritamabDetails.q38step_up === 99 ? true : false,

                //question 39 payload
                dose2and3DaysDifference: epcoritamabDetails.q39_days_txt,
                dose2and3DaysDifferenceUnk:
                    epcoritamabDetails.q39step_up === 99 ? true : false,

                //question 40
                discontinueEpcoritamab: epcoritamabDetails.q40Discont,

                //question 41
                epcoritamabDiscontDate:
                    epcoritamabDetails.q40Discont === 1 &&  !IsDateEmpty(epcoritamabDetails.dt_discont) &&
                        epcoritamabDetails.q41EpDiscontDtUnk === 1
                        ? ParseDate(epcoritamabDetails.dt_discont)
                        : null,
                epcoritamabDiscontDateDmuid:
                    epcoritamabDetails.q40Discont === 1 &&
                        epcoritamabDetails.q41EpDiscontDtUnk === 1
                        ? GetDMUID(epcoritamabDetails.dt_discont)
                        : null,
                epcoritamabDiscontDateUnk:
                    epcoritamabDetails.q40Discont === 1 &&
                        epcoritamabDetails.q41EpDiscontDtUnk === 99
                        ? epcoritamabDetails.q41EpDiscontDtUnk
                        : false,

                //question 42

                epcoritamabDiscontReason: epcoritamabDetails.q42DiscontReason,
                epcoritamabDiscontReasonOthTxt:
                    epcoritamabDetails.q42DiscontReason === 10
                        ? epcoritamabDetails.q42DiscontReasonOtherTxt
                        : null,

                EpcorDetailsList: [],

                createdby: GetLoggedInUserID() ?? 0,
                editedby: GetLoggedInUserID() ?? 0,
                userId: GetLoggedInUserID(),
                isValidated: validate,
            };

            if (epcorAssessmentsDetails && epcorAssessmentsDetails.length > 0) {
                epcoritamabModel.EpcorDetailsList = epcorAssessmentsDetails.map(
                    (a) => ({
                        Epcoritamabradiologicalassessmentid:
                            a.epcoritamabradiologicalassessmentid,
                        RadiologicalAssessmentDate:
                            a.radiological_assess_dx === 1 &&  !IsDateEmpty(a.radiological_assess_dx_date) 
                                ? ParseDate(a.radiological_assess_dx_date)
                                : null,
                        RadiologicalAssessmentDateDmuid:
                            a.radiological_assess_dx === 1
                                ? GetDMUID(a.radiological_assess_dx_date)
                                : null,
                        RadiologicalAssessmentDateUnk: a.radiological_assess_dx === 99,

                        LbclDiseaseCategory: a.q44_LBCL_status,
                        LbclDiseaseCategoryOthTxt: a.q44_lbcl_oth_txt,

                        LyricLuganoAssessment: a.q45_stage_status,
                        LyricCriteria:
                            a.q45_stage_status === 1 || a.q45_stage_status === 3
                                ? a.q46LyricAssess
                                : null,

                        RadiologicalAssessmentType:
                            // a.q45_stage_status === 2 || a.q45_stage_status === 4
                            //   ? 
                            a.q47_radiology_type,
                        // : null,
                        RadiologicalAssessmentTypeOthTxt: a.q47RadioAssessTypeOtherTxt,
                    })
                );
            }

            if (pid > 0) {
                debugger
                const response = await TreatmentResponseService.SaveTreatmentResponse(
                    epcoritamabModel
                );
                ToggleLoader(false);
                if (response.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized) {
                        HandleSessionTimeout();
                    } else throw response.error;
                }
                else {
                    await LoadData()
                    ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                    //All lots removed 
                    if((epcoritamabDetails.selectedLOT > 1)) {
                        navigatePaths.nextPage = "/TreatmentsPriorEpcoritamab/1";
                    }else{
                        navigatePaths.nextPage = "/CARTCellTherapy";                        
                    }
                }
                
            }
            return true;
        }else{
            ToggleLoader(false);
            setShowTopErrMsg(true)
            return false;
        }
        } catch (error) {
            ToggleLoader(false);
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
        }
    };

    useEffect(() => {
        debugger;
        async function FetchData() {
            if (!isNaN(patientId) && Number(patientId) !== 0) {
                ToggleLoader(true);
                await LoadData();
                ToggleLoader(false);
            }
        }
        FetchData();
    }, []);

    const LoadData = async () => {
        // debugger
        try {
            if (patientId > 0) {
                const response =
                    await TreatmentResponseService.GetAllEpcoritamabResponse(patientId);

                if (response?.status === HTTPResponse.OK) {
                    const { data } = response;
                    setepcoritamabDetails((prev) => ({
                        ...prev,
                        userId: data.userId,

                        selectedLOT: data.epcorLot,
                        q36_state: data.epcorLot !== null ? 1 : null,

                        q37Mono: data.receivedMonotherapyTreatment === true ? 1 : false,
                        q37Lena: data.otherTreatmentLenalidomide,
                        q37Lonca: data.otherTreatmentLoncastuximab,
                        q37Ritux: data.otherTreatmentRituximab,
                        q37Vene: data.otherTreatmentVenetoclax,
                        q37InvestAgent: data.otherTreatmentInvestigationalagent,
                        q37InvestAgent_txt: data.otherTreatmentInvestigationalagentTxt,
                        q37Other: data.otherTreatmentOth,
                        q37Other_txt: data.otherTreatmentOthTxt,

                        q38step_up:
                            data.dose1and2DaysDifference !== null
                                ? 1
                                : data.dose1and2DaysDifferenceUnk === true
                                    ? 99
                                    : null,
            q38_days_txt: data.dose1and2DaysDifference??"",

                        //  q39step_up: data.dose2and3DaysDifference !== null ? 1 : null,
                        //  q39step_up: data.dose2and3DaysDifferenceUnk === true ? 99 : 1,

                        q39step_up:
                            data.dose2and3DaysDifference !== null
                                ? 1
                                : data.dose2and3DaysDifferenceUnk === true
                                    ? 99
                                    : null,
            q39_days_txt: data.dose2and3DaysDifference??"",

                        q40Discont:
                            data.discontinueEpcoritamab != null
                                ? data.discontinueEpcoritamab
                                : null,

                        dt_discont: !isStringEmpty(data.epcoritamabDiscontDate)
                            ? GetDisplayDate(
                                data.epcoritamabDiscontDate,
                                data.epcoritamabDiscontDateDmuid
                            )
                            : DefaultDate,

                        q41EpDiscontDtUnk: data.epcoritamabDiscontDateUnk
                            ? 99
                            : data.epcoritamabDiscontDate != null
                                ? 1
                                : null,

                        q42DiscontReason: data.epcoritamabDiscontReasonOthTxt
                            ? 10
                            : data.epcoritamabDiscontReason,
                        q42DiscontReasonOtherTxt: data.epcoritamabDiscontReasonOthTxt ?? "",
                    }));

                    //Repeater load data
                    debugger;
                    if (
                        data.epcorDetailsList != null &&
                        data.epcorDetailsList.length > 0
                    ) {
                        const { epcorDetailsList } = data;
                        const epcorDetailsArray = epcorDetailsList.map((item, index) => {
                            const epcoritamabradiologicalassessmentid =
                                item.epcoritamabradiologicalassessmentid != null
                                    ? item.epcoritamabradiologicalassessmentid
                                    : 0;
                            const radiological_assess_dx_date = !isStringEmpty(
                                item.radiologicalAssessmentDate
                            )
                                ? GetDisplayDate(
                                    item.radiologicalAssessmentDate,
                                    item.radiologicalAssessmentDateDmuid
                                )
                                : DefaultDate;

                            // Determine initial visibility for Q46 and Q47 based on q45_stage_status value
                            if (
                                item.lyricLuganoAssessment === 1 ||
                                item.lyricLuganoAssessment === 3
                            ) {
                                setShowQ46((prev) => ({ ...prev, [index]: true }));
                                //   setShowQ47((prev) => ({ ...prev, [index]: false }));
                            } else if (
                                item.lyricLuganoAssessment === 2 ||
                                item.lyricLuganoAssessment === 4
                            ) {
                                setShowQ46((prev) => ({ ...prev, [index]: false }));
                                //  setShowQ47((prev) => ({ ...prev, [index]: true }));
                            }
                            return {
                                ...EPCORAssesmentRow,
                                epcoritamabradiologicalassessmentid,
                                radiological_assess_dx: item.radiologicalAssessmentDateUnk
                                    ? 99
                                    : !isStringEmpty(item.radiologicalAssessmentDate)
                                        ? 1
                                        : null,
                                radiological_assess_dx_date,

                                q44_LBCL_status: item.lbclDiseaseCategory,
                                q44_lbcl_oth_txt: item.lbclDiseaseCategoryOthTxt,

                                q45_stage_status: item.lyricLuganoAssessment,

                                q46LyricAssess: item.lyricCriteria,

                                q47_radiology_type: item.radiologicalAssessmentType,
                                q47RadioAssessTypeOtherTxt:
                                    item.radiologicalAssessmentTypeOthTxt,
                            };
                        });
                        setEPCORAssessmentsDetails(epcorDetailsArray);
                    }

                    if (data.indexDate) {
                        console.log("Index Date:", data.indexDate);
                        setIndexDate(data.indexDate);
                    }

                    if (data.chartAbstractDate) {
                        console.log("Chart:", data.chartAbstractDate);
                        setChartAbstractionDate(data.chartAbstractDate);
                    }

                    if (data.deathDate) {
                        console.log("Death:", data.deathDate);
                        set_Death_Date(data.deathDate);
                    }
                } else if (response?.status === HTTPResponse.Unauthorized) {
                    HandleSessionTimeout();
                }
            }
        } catch (error) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    };

    const HandleDateChange = (name, date, datePart) => {
        debugger;
        switch (name) {
            case "dt_discont": {
                setepcoritamabDetails((prev) => ({
                    ...prev,
                    q41EpDiscontDtUnk: 1,
                    dt_discont: SetDateState(prev.dt_discont, date, datePart),
                }));

                setSoftErrList((prev) => ({
                    ...prev,
                    q41Unk_err: false,
                }));

                setHardErrList((prev) => ({
                    ...prev,
                    q41Empty: false,
                    // q41_date_invalid1:false,
                    q41_date_afterIndex:false,
                    q41_date_beforeDeathorChart:false,
                }));
                break;
            }

            default:
                break;
        }
    };

    //const [selectedLOT, setSelectedLOT] = useState(null);
    return (
        <>
            <section className="survey-patient-details-section">
                <div className="row">
                    <PatientDetails />
                    <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
                        <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                    </div>
                </div>
            </section>
            <section className="main-survey-section">
                <div className="row">
                    <SideBarMenu activePageId={SideMenuItems.EpcoritamabTreatment} />
                    <div className="col-md-9 col-pad-left">
                        <div className="survey-section-content">
                            <div className="survey-section-content-header">
                                <h4>Epcoritamab Treatment</h4>
                            </div>
                            {showTopErrMsg && <div className="clean-error ">
                                <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                            </div>
                            }
                            <div className="survey-question-section">
                                <div className="survey-question-content">
                                    <div style={PageDesignConstants.PageDescriptionStyle}>
                                        <i>
                                            In this section, you will be asked to provide information
                                            on the epcoritamab treatment the patient received. You
                                            will also be asked to provide information on any
                                            additional treatment received as combination therapy with
                                            epcoritamab.
                                        </i>
                                    </div>
                                    <div className="question-answer">
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q36.</span>
                                                <span className="quest-text-pad">
                                                    In which line of therapy did the patient receive
                                                    epcoritamab?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q36Select_1"
                                                        name="q36_state"
                                                        value={1}
                                                        checked={epcoritamabDetails.q36_state === 1}
                                                        onChange={HandleFieldChange}
                                                    />

                                                    {/* <label htmlFor="q36Select_1">
                            Select LOT */}
                                                    <span className="radio-text-padding ">
                                                        <Dropdown className="custom-dropdown-caret">
                                                            <Dropdown.Toggle
                                                                variant=""
                                                                id="dropdown-basic"
                                                                className="w110 epcoritamab-custom-dropdown-button"
                                                            >
                                                                {epcoritamabDetails.selectedLOT !== null
                                                                    ? epcoritamabDetails.selectedLOT
                                                                    : "Select LOT"}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="epcoritamab-custom-dropdown-menu">
                                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                                                                    <Dropdown.Item
                                                                        as="button"
                                                                        name="selectedLOT"
                                                                        key={num}
                                                                        value={num}
                                    className={`custom-dropdown-item ${
                                      num === epcoritamabDetails.selectedLOT &&
                                                                            "custom-dropdown-item-selected"
                                                                            }`}
                                                                        style={{ width: "100% !important" }}
                                                                        onClick={HandleFieldChange}
                                                                    >
                                                                        {num}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </span>
                                                    {/* </label> */}
                                                </div>
                                                {/* <div className="">
                          <input
                            type="radio"
                            id="q36Select_2"
                            name="q36_state"
                            value={2}
                            checked={
                              epcoritamabDetails.q36_state === 2
                            }
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="q36Select_2">
                            <span className="radio-text-padding">
                              Other, specify:{" "}
                              <input
                                className="input-dash"
                                name="q36_other_txt"
                                type="text"
                                value={
                                  epcoritamabDetails.q36_other_txt }
                                //maxLength={100}
                                onChange={HandleFieldChange}
                              />
                            </span>
                          </label>
                        </div> */}
                                            </div>
                                            <ErrorField
                                                show={hardErrList.q36Empty}
                                                message={Epcoritamab_Msgs.Q36}
                                            />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q37.</span>
                                                <span className="quest-text-pad">
                                                    Did the patient receive epcoritamab in combination
                                                    with any other treatment?{" "}
                                                    <i>(select all that apply)</i>
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q37Mono"
                                                        name="q37Mono"
                                                        value={1}
                                                        checked={epcoritamabDetails.q37Mono === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q37Mono">
                                                        <span className="radio-text-padding">
                                                            The patient received the treatment as monotherapy
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="q37Lena"
                                                        name="q37Lena"
                                                        checked={epcoritamabDetails.q37Lena}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q37Lena">
                                                        <span className="radio-text-padding">
                                                            Lenalidomide
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="q37Lonca"
                                                        name="q37Lonca"
                                                        checked={epcoritamabDetails.q37Lonca}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q37Lonca">
                                                        <span className="radio-text-padding">
                                                            Loncastuximab
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="q37Ritux"
                                                        name="q37Ritux"
                                                        checked={epcoritamabDetails.q37Ritux}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q37Ritux">
                                                        <span className="radio-text-padding">
                                                            Rituximab, cyclophosphamide, doxorubicin
                                                            hydrochloride, vincristine and prednisone (R-CHOP)
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="q37Vene"
                                                        name="q37Vene"
                                                        checked={epcoritamabDetails.q37Vene}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q37Vene">
                                                        <span className="radio-text-padding">
                                                            Venetoclax
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="q37InvestAgent"
                                                        name="q37InvestAgent"
                                                        checked={epcoritamabDetails.q37InvestAgent}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="q37InvestAgent">
                                                        <span className="radio-text-padding">
                                                            Investigational agent:{" "}
                                                            <input
                                                                className="input-dash"
                                                                name="q37InvestAgent_txt"
                                                                type="text"
                                                                value={epcoritamabDetails.q37InvestAgent_txt}
                                                                maxLength={100}
                                                                onChange={HandleFieldChange}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="checkbox"
                                                        id="raceOther"
                                                        name="q37Other"
                                                        checked={epcoritamabDetails.q37Other}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label htmlFor="raceOther">
                                                        <span className="radio-text-padding">
                                                            Other, specify:{" "}
                                                            <input
                                                                className="input-dash"
                                                                name="q37Other_txt"
                                                                type="text"
                                                                value={epcoritamabDetails.q37Other_txt}
                                                                maxLength={100}
                                                                onChange={HandleFieldChange}
                                                            />
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>

                                            <ErrorField
                                                show={hardErrList.q37Empty}
                                                message={Epcoritamab_Msgs.Q37}
                                                style={{ paddingLeft: "24px" }}
                                            />
                                            <ErrorField
                                                show={hardErrList.q37EmptyagentTxt}
                                                message="Please enter Investigational agent."
                                            />
                                            <ErrorField
                                                show={hardErrList.q37EmptyOtherTxt}
                                                message="Please specify other text."
                                            />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q38.</span>
                                                <span className="quest-text-pad">
                                                    How many days were there between the patient’s
                                                    epcoritamab step up dose 1 and 2?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q38Select_1"
                                                        name="q38step_up"
                                                        value={1}
                                                        checked={epcoritamabDetails.q38step_up === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    {/* <label htmlFor="q38Select_1">
                            <span className="radio-text-padding">Days:</span>
                          </label> */}
                                                    <span className="radio-text-padding">
                                                        <input
                                                            className="sub-input-dash"
                                                            name="q38_days_txt"
                                                            type="text"
                                                            value={epcoritamabDetails.q38_days_txt}
                                                            maxLength={100}
                                                            onChange={HandleFieldChange}
                                                        />
                                                    </span>{" "}
                                                    days
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q38Unk_99"
                                                        name="q38step_up"
                                                        value={99}
                                                        checked={epcoritamabDetails.q38step_up === 99}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label>
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <ErrorField show={hardErrList.q18Empty} message={demographicScreen_Msgs.Q18} /> */}
                                            <ErrorField
                                                show={softErrList.q38Unk_err}
                                                message={CommonMsgs.Unknown}
                                            />
                                            <ErrorField
                                                show={hardErrList.q38Empty}
                                                message={Epcoritamab_Msgs.Q38}
                                            />

                                            <ErrorField show={hardErrList.q38SizeOutOfRange} message={Epcoritamab_Msgs.DaysRangeLimit} />

                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q39.</span>
                                                <span className="quest-text-pad">
                                                    How many days were there between the patient’s
                                                    epcoritamab step up dose 2 and 3 (first full dose)?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q39Select_1"
                                                        name="q39step_up"
                                                        value={1}
                                                        checked={epcoritamabDetails.q39step_up === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    {/* <label htmlFor="q39Select_1">
                            <span className="radio-text-padding">Days:</span>
                          </label>  */}
                                                    <span className="radio-text-padding">
                                                        <input
                                                            className="sub-input-dash"
                                                            name="q39_days_txt"
                                                            type="text"
                                                            value={epcoritamabDetails.q39_days_txt}
                                                            maxLength={100}
                                                            onChange={HandleFieldChange}
                                                        />
                                                    </span>{" "}
                                                    days
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q39Unk_99"
                                                        name="q39step_up"
                                                        value={99}
                                                        checked={epcoritamabDetails.q39step_up === 99}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label>
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <ErrorField show={hardErrList.q18Empty} message={demographicScreen_Msgs.Q18} /> */}
                                            <ErrorField
                                                show={softErrList.q39Unk_err}
                                                message={CommonMsgs.Unknown}
                                            />

                                            <ErrorField
                                                show={hardErrList.q39Empty}
                                                message={Epcoritamab_Msgs.Q39}
                                            />
                                            <ErrorField show={hardErrList.q39SizeOutOfRange} message={Epcoritamab_Msgs.DaysRangeLimit} />
                                        </div>
                                        <div className="question-bot-sapce">
                                            <div className="question question-weight">
                                                <span>Q40.</span>
                                                <span className="quest-text-pad">
                                                    Did the patient discontinue epcoritamab?
                                                </span>
                                            </div>
                                            <div className=" double-dig-answer">
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q40DiscontSelect_1"
                                                        name="q40Discont"
                                                        value={1}
                                                        // checked={true}
                                                        checked={epcoritamabDetails.q40Discont === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label>
                                                        <span className="radio-text-padding">Yes</span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q40DiscontSelect_0"
                                                        name="q40Discont"
                                                        value={0}
                                                        checked={epcoritamabDetails.q40Discont === 0}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label>
                                                        <span className="radio-text-padding">
                                                            No, the patient is still on treatment
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="radio"
                                                        id="q40DiscontSelect_99"
                                                        name="q40Discont"
                                                        value={99}
                                                        checked={epcoritamabDetails.q40Discont === 99}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <label>
                                                        <span className="radio-text-padding">Unknown</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <ErrorField show={hardErrList.q19Empty} message={demographicScreen_Msgs.Q19} /> */}
                                            <ErrorField
                                                show={softErrList.q40Unk_err}
                                                message={CommonMsgs.Unknown}
                                            />
                                            <ErrorField
                                                show={hardErrList.q40Empty}
                                                message={Epcoritamab_Msgs.Q40}
                                            />
                                        </div>

                                        {epcoritamabDetails.q40Discont === 1 && (
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>Q41.</span>
                                                    <span className="quest-text-pad">
                                                        What was the date of epcoritamab discontinuation?
                                                    </span>
                                                </div>
                                                <div className=" double-dig-answer">
                                                    <div className="date-field-padding">
                                                        <input
                                                            type="radio"
                                                            id="q41EpDiscontDtUnk_1"
                                                            name="q41EpDiscontDtUnk"
                                                            value={1}
                                                            checked={
                                                                epcoritamabDetails.q41EpDiscontDtUnk === 1
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                            <DateControl
                                                                ctrlId={"dt_discont"}
                                                                HandleDateChange={HandleDateChange}
                                                                date={epcoritamabDetails.dt_discont}
                                                                showUnkInfoTxt={true}
                                                            />
                                                            {/* <i>
                                If the exact month and/or day is unknown, please
                                enter UNK.
                              </i> */}
                                                        </span>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q41EpDiscontDtUnk_99"
                                                            name="q41EpDiscontDtUnk"
                                                            value={99}
                                                            checked={
                                                                epcoritamabDetails.q41EpDiscontDtUnk === 99
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="q41EpDiscontDtUnk">
                                                            <span className="radio-text-padding">
                                                                Unknown
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <ErrorField
                                                    show={softErrList.q41Unk_err}
                                                    message={CommonMsgs.Unknown}
                                                />

                                                <ErrorField
                                                    show={hardErrList.q41Empty}
                                                    message={Epcoritamab_Msgs.Q41}
                                                />
                                                <ErrorField
                                                    show={hardErrList.q41_date_afterIndex}
                                                    message={Epcoritamab_Msgs.Q41_Invalid1}
                                                />
                                                <ErrorField
                                                    show={hardErrList.q41_date_beforeDeathorChart}
                                                    message={Epcoritamab_Msgs.Q41_Invalid2}
                                                />
                                            </div>
                                        )}
                                        {epcoritamabDetails.q40Discont === 1 && (
                                            <div className="question-bot-sapce">
                                                <div className="question question-weight">
                                                    <span>Q42.</span>
                                                    <span className="quest-text-pad">
                                                        What was the reason for discontinuation of
                                                        epcoritamab?
                                                    </span>
                                                </div>
                                                <div className=" double-dig-answer">
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_1"
                                                            name="q42DiscontReason"
                                                            value={1}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 1
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                LBCL disease progression
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_2"
                                                            name="q42DiscontReason"
                                                            value={2}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 2
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                Eligibility for clinical trial treatment
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_3"
                                                            name="q42DiscontReason"
                                                            value={3}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 3
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                Patient preference
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_4"
                                                            name="q42DiscontReason"
                                                            value={4}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 4
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label htmlFor="q42DiscontReasonSelect_4">
                                                            <span className="radio-text-padding">
                                                                Decision to proceed with transplant
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_5"
                                                            name="q42DiscontReason"
                                                            value={5}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 5
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                Clinical decision as patient experienced
                                                                complete response
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_6"
                                                            name="q42DiscontReason"
                                                            value={6}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 6
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                Physician decision due to inadequate response
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_7"
                                                            name="q42DiscontReason"
                                                            value={7}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 7
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                COVID-19 infection
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_8"
                                                            name="q42DiscontReason"
                                                            value={8}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 8
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                Decided to proceed with CAR T cell therapy
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_9"
                                                            name="q42DiscontReason"
                                                            value={9}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 9
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                Patient’s choice/decision
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_10"
                                                            name="q42DiscontReason"
                                                            value={10}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 10
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                Other, specify:{" "}
                                                                <input
                                                                    className="input-dash"
                                                                    name="q42DiscontReasonOtherTxt"
                                                                    type="text"
                                                                    maxLength={100}
                                                                    value={
                                                                        epcoritamabDetails.q42DiscontReasonOtherTxt
                                                                    }
                                                                    onChange={HandleFieldChange}
                                                                />
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="">
                                                        <input
                                                            type="radio"
                                                            id="q42DiscontReasonSelect_99"
                                                            name="q42DiscontReason"
                                                            value={99}
                                                            checked={
                                                                epcoritamabDetails.q42DiscontReason === 99
                                                            }
                                                            onChange={HandleFieldChange}
                                                        />
                                                        <label>
                                                            <span className="radio-text-padding">
                                                                Unknown
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField
                                                    show={hardErrList.q42Empty}
                                                    message={Epcoritamab_Msgs.Q42}
                                                />
                                                <ErrorField
                                                    show={softErrList.q42Unk_err}
                                                    message={CommonMsgs.Unknown}
                                                />


                              <ErrorField
                                show={hardErrList.q42txtEmpty}
                                  message={Epcoritamab_Msgs.q47TxtEmpty}
                                />
                                            </div>
                                        )}
                                        {/* Set 1 start */}

                                        <div className="dependent-section">
                                            {epcorAssessmentsDetails &&
                                                epcorAssessmentsDetails.length > 0 &&
                                                epcorAssessmentsDetails.map((data, idx) => (

                                                    <div className="loop-section-ul">
                                                        <div className="loop-head">
                                                            {/* <span className="question-weight">1<sup>st</sup> Radiological Assessment</span> */}
                                                            <span className="question-weight">
                                                                {GetOrdinal(idx + 1)} Radiological Assessment
                                                            </span>
                                                            {idx != 0 && (
                                                                <div className="delete-btn-div">
                                                                    <img
                                                                        width="16px"
                                                                        src="../Assets/images/Icon-material-delete.png"
                                                                        alt=""
                                                                        onClick={() =>
                                                                            DeleteEPCORAssessmentRow(idx)
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                            <div className="question question-weight">
                                                                <span>Q43.</span>
                                                                <span className="quest-text-pad">
                                                                    What was the date of the {GetOrdinalInWord(idx + 1)} radiological
                                                                    assessment after the patient initiated
                                                                    epcoritamab?
                                                                </span>
                                                            </div>
                                                            <div className=" double-dig-answer">
                                                                <div className="date-field-padding">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"radiological_assess_dx" + idx}
                                                                        value={1}
                                                                        checked={data.radiological_assess_dx === 1}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                    />
                                                                    <span className="radio-text-padding">
                                                                        <DateControl
                                                                            ctrlId={"radiological_dx_date-" + idx}
                                                                            HandleDateChange={
                                                                                HandleRepeaterDateChange
                                                                            }
                                                                            date={data.radiological_assess_dx_date}
                                                                            showUnkInfoTxt={true}
                                                                        />
                                                                        {/* <i>
                                      If the exact month and/or day is unknown,
                                      please enter UNK.
                                    </i> */}
                                                                    </span>
                                                                </div>
                                                                <div className="">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={idx}
                                                                            name={"radiological_assess_dx" + idx}
                                                                            value={99}
                                                                            checked={
                                                                                data.radiological_assess_dx === 99
                                                                            }
                                                                            onChange={HandleRepeaterFieldChange}
                                                                        />

                                                                        <span className="radio-text-padding">
                                                                            Unknown
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField
                                                                show={data.radiologyDxDateUnkSoft}
                                                                message={CommonError_Msgs.UnknownSelect}
                                                            />
                                                            <ErrorField
                                                                show={data.radiologyDxEmptyHard}
                                                                message={Epcoritamab_Msgs.radiologyDxEmpty.replace(
                                                                    "{id}",
                                                                    GetOrdinalInWord(idx + 1)
                                                                )}
                                                            />

                                                            <ErrorField
                                                                show={data.q43afterindexEmptyHard}
                                                                message={Epcoritamab_Msgs.After_index_dt.replace(
                                                                    "{id}",
                                                                    GetOrdinalInWord(idx + 1)
                                                                )}
                                                            />
                                                            <ErrorField
                                                                show={data.q43beforeQ41dateEmptyHard}
                                                                message={Epcoritamab_Msgs.Before_discnt_dt.replace(
                                                                    "{id}",
                                                                    GetOrdinalInWord(idx + 1)
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                            <div className="question question-weight">
                                                                <span>Q44.</span>
                                                                <span className="quest-text-pad">
                                                                    Which of the following categories best
                                                                    describes the patient’s LBCL disease status at
                                                                    this radiological assessment? This can be
                                                                    based on the conclusion of the radiologic
                                                                    report or physician’s notes in the chart.
                                                                </span>
                                                            </div>
                                                            <div className=" double-dig-answer">
                                                                <div className="">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={idx}
                                                                            name={"q44_LBCL_status" + idx}
                                                                            value={1}
                                                                            onChange={HandleRepeaterFieldChange}
                                                                            checked={data.q44_LBCL_status === 1}
                                                                        />

                                                                        <span className="radio-text-padding">
                                                                            Clinician-assessed complete response
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={idx}
                                                                            name={"q44_LBCL_status" + idx}
                                                                            value={2}
                                                                            onChange={HandleRepeaterFieldChange}
                                                                            checked={data.q44_LBCL_status === 2}
                                                                        />

                                                                        <span className="radio-text-padding">
                                                                            Clinician-assessed less-than-complete
                                                                            response (significant tumor shrinkage)
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <label className="long-text-option">
                                                                        <input
                                                                            type="radio"
                                                                            id={idx}
                                                                            name={"q44_LBCL_status" + idx}
                                                                            value={3}
                                                                            onChange={HandleRepeaterFieldChange}
                                                                            checked={data.q44_LBCL_status === 3}
                                                                        />
                                                                        <span className="radio-text-padding">
                                                                            Clinician-assessed stable disease (may
                                                                            include minimal increases or decreases in
                                                                            size of tumor or permitting ongoing
                                                                            systemic therapy)
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q44_LBCL_status" + idx}
                                                                        value={4}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q44_LBCL_status === 4}
                                                                    />
                                                                    <label htmlFor="q44Select_4">
                                                                        <span className="radio-text-padding">
                                                                            Progressive disease
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q44_LBCL_status" + idx}
                                                                        value={5}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q44_LBCL_status === 5}
                                                                    />
                                                                    <label htmlFor="q44Select_5">
                                                                        <span className="radio-text-padding">
                                                                            Other, specify:{" "}
                                                                            <input
                                                                                className="input-dash"
                                                                                name={"q44_lbcl_oth_txt"}
                                                                                id={idx}
                                                                                type="text"
                                                                                maxLength={100}
                                                                                value={data.q44_lbcl_oth_txt}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q44_LBCL_status" + idx}
                                                                        value={99}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q44_LBCL_status === 99}
                                                                    />
                                                                    <label htmlFor="q44Select_99">
                                                                        <span className="radio-text-padding">
                                                                            Unknown
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField
                                                                show={data.q44StatusEmptyHard}
                                                                message={Epcoritamab_Msgs.q44HardEmpty}
                                                            />
                                                            <ErrorField
                                                                show={data.q44StatusUnkSoft}
                                                                message={CommonError_Msgs.UnknownSelect}
                                                            />
                                                            <ErrorField
                                                                show={data.q44lbclTxtEmptyHard}
                                                                message={Epcoritamab_Msgs.q44lbclTxtEmpty}
                                                            />
                                                        </div>
                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                            <div className="question question-weight">
                                                                <span>Q45.</span>
                                                                <span className="quest-text-pad">
                                                                    Was the disease stage assessed per LYRIC or
                                                                    Lugano criteria?
                                                                </span>
                                                            </div>
                                                            <div className=" double-dig-answer">
                                                                <div className="">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={idx}
                                                                            name={"q45_stage_status" + idx}
                                                                            value={1}
                                                                            checked={data.q45_stage_status === 1}
                                                                            onChange={HandleRepeaterFieldChange}
                                                                        />
                                                                        <span className="radio-text-padding">
                                                                            LYRIC
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={idx}
                                                                            name={"q45_stage_status" + idx}
                                                                            value={2}
                                                                            checked={data.q45_stage_status === 2}
                                                                            onChange={HandleRepeaterFieldChange}
                                                                        />

                                                                        <span className="radio-text-padding">
                                                                            Lugano
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={idx}
                                                                            name={"q45_stage_status" + idx}
                                                                            value={3}
                                                                            checked={data.q45_stage_status === 3}
                                                                            onChange={HandleRepeaterFieldChange}
                                                                        />

                                                                        <span className="radio-text-padding">
                                                                            LYRIC and Lugano
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <label>
                                                                        <input
                                                                            type="radio"
                                                                            id={idx}
                                                                            name={"q45_stage_status" + idx}
                                                                            value={4}
                                                                            checked={data.q45_stage_status === 4}
                                                                            onChange={HandleRepeaterFieldChange}
                                                                        />

                                                                        <span className="radio-text-padding">
                                                                            Neither
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField
                                                                show={data.q45statusEmptyHard}
                                                                message={Epcoritamab_Msgs.q45StatusEmpty}
                                                            />
                                                        </div>
                                                        {showQ46[idx] && (
                                                            <div className="sub-question-bot-sapce answer-pad-left">
                                                                <div className="question question-weight">
                                                                    <span>Q46.</span>
                                                                    <span className="quest-text-pad">
                                                                        What was the disease assessment per LYRIC
                                                                        criteria?
                                                                    </span>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={1}
                                                                                checked={data.q46LyricAssess === 1}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                IR1
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={2}
                                                                                checked={data.q46LyricAssess === 2}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                IR2
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={3}
                                                                                checked={data.q46LyricAssess === 3}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                IR1 and IR3
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={4}
                                                                                checked={data.q46LyricAssess === 4}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                IR2 and IR3
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={5}
                                                                                checked={data.q46LyricAssess === 5}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                IR3
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={6}
                                                                                checked={data.q46LyricAssess === 6}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                Stable disease
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={7}
                                                                                checked={data.q46LyricAssess === 7}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                Partial response
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={8}
                                                                                checked={data.q46LyricAssess === 8}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                Complete response
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className=" double-dig-answer">
                                                                    <div className="">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                id={idx}
                                                                                name={"q46LyricAssess" + idx}
                                                                                value={9}
                                                                                checked={data.q46LyricAssess === 9}
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />

                                                                            <span className="radio-text-padding">
                                                                                Progressive disease
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <ErrorField
                                                                    show={data.q46LyricEmptyHard}
                                                                    message={Epcoritamab_Msgs.q46LyricEmptyHard}
                                                                />
                                                            </div>
                                                        )}

                                                        {/* {showQ47[idx] && ( */}
                                                        <div className="sub-question-bot-sapce answer-pad-left">
                                                            <div className="question question-weight">
                                                                <span>Q47.</span>
                                                                <span className="quest-text-pad">
                                                                    What type of radiological assessment was
                                                                    used?
                                                                </span>
                                                            </div>
                                                            <div className=" double-dig-answer">
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q47_radiology_type" + idx}
                                                                        value={1}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q47_radiology_type === 1}
                                                                    />
                                                                    <label htmlFor="q47RadioAssessTypeSelect_1">
                                                                        <span className="radio-text-padding">
                                                                            PET or PET/CT
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q47_radiology_type" + idx}
                                                                        value={2}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q47_radiology_type === 2}
                                                                    />
                                                                    <label htmlFor="q47RadioAssessTypeSelect_2">
                                                                        <span className="radio-text-padding">
                                                                            CT
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q47_radiology_type" + idx}
                                                                        value={3}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q47_radiology_type === 3}
                                                                    />
                                                                    <label htmlFor="q47RadioAssessTypeSelect_3">
                                                                        <span className="radio-text-padding">
                                                                            Other imaging
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q47_radiology_type" + idx}
                                                                        value={4}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q47_radiology_type === 4}
                                                                    />
                                                                    <label htmlFor="q47RadioAssessTypeSelect_4">
                                                                        <span className="radio-text-padding">
                                                                            Clinical only (no imaging)
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q47_radiology_type" + idx}
                                                                        value={5}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q47_radiology_type === 5}
                                                                    />
                                                                    <label htmlFor="q47RadioAssessTypeSelect_5">
                                                                        <span className="radio-text-padding">
                                                                            Other, specify:{" "}
                                                                            <input
                                                                                className="input-dash"
                                                                                name={"q47RadioAssessTypeOtherTxt"}
                                                                                id={idx}
                                                                                type="text"
                                                                                maxLength={100}
                                                                                value={
                                                                                    data.q47RadioAssessTypeOtherTxt
                                                                                }
                                                                                onChange={HandleRepeaterFieldChange}
                                                                            />
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="">
                                                                    <input
                                                                        type="radio"
                                                                        id={idx}
                                                                        name={"q47_radiology_type" + idx}
                                                                        value={99}
                                                                        onChange={HandleRepeaterFieldChange}
                                                                        checked={data.q47_radiology_type === 99}
                                                                    />
                                                                    <label htmlFor="q47RadioAssessTypeSelect_99">
                                                                        <span className="radio-text-padding">
                                                                            Unknown
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <ErrorField
                                                                show={data.q47radiologyTypeEmptyHard}
                                                                message={Epcoritamab_Msgs.q47HardEmpty}
                                                            />
                                                            <ErrorField
                                                                show={data.q47radiologyTypeUnkSoft}
                                                                message={CommonError_Msgs.UnknownSelect}
                                                            />
                                                            <ErrorField
                                                                show={data.q47radiologyTypeTxtEmptyHard}
                                                                message={Epcoritamab_Msgs.q47TxtEmpty}
                                                            />
                                                        </div>
                                                        {/* )} */}
                                                    </div>
                                                ))}
                                            {epcorAssessmentsDetails &&
                                                epcorAssessmentsDetails.length < 8 && (
                                                    <div className="sub-question-bot-sapce-ul mt-2">
                                                        <div
                                                            className="answer-list-text"
                                                            onClick={AddEPCORAssessmentRow}
                                                        >
                                                            <a>
                                                                <img
                                                                    width="18px"
                                                                    src="../Assets/images/plus.png"
                                                                    alt=""
                                                                />
                                                            </a>
                                                            <a className="radio-text-padding">
                                                                Click here to add another radiological
                                                                assessment
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}

                                            {/* Set 1 end */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="survey-section-content-foot">
                                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Epcoritamab;
