import React, { useContext, useEffect, useState } from "react";
import { HTTPResponse, PageAction, Role, ToastMessageType, ToastMessages } from "../../Helpers/Enums";
import { GetLoggedInUserID, IsEmailValid, isStringEmpty } from "../../Helpers/Utilities";
import ErrorField from "../../Components/ErrorField";
import { AbstractorService } from "../../WebApiServices/Abstractor.service";
import { AppContext } from "../../Contexts/AppContextProvider";
import { Abstractor_Msgs, CommonError_Msgs } from "../../Helpers/HelperText";
import { CenterService } from "../../WebApiServices/Center.service";

const AbstractorForm = ({ abstractor, pageAction, CloseModal }) => {//centers
    const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);

    const CloseModalPopUp = (id) => { 
        CloseModal({ ...abstractorDetails, abstractorId: id })
    } 
    const [abstractorDetails, setAbstractorDetails] = useState({
        abstractorId: abstractor?.abstractorId ?? 0,
        firstName: abstractor?.firstName,
        lastName: abstractor?.lastName,
        emailAddress: abstractor?.emailAddress,
        centerId: abstractor?.centerId ?? 0,
        centerName: abstractor.centerName,
        isNew: !abstractor.abstractorId || abstractor?.abstractorId === 0,
        roleId: abstractor?.roleId ?? 0
    })
    const [errors, setErrors] = useState({
        firstName: false,
        lastName: false,
        emailEmpty: false,
        emailInvalid: false,
        centerId: false
    });
    const [errorOnSave, setErrorOnSave] = useState("");
    const [centers, setCenters] = useState([]);
    const GetAllCenters = async () => {
        try {
            const response = abstractorDetails.roleId == Role.Admin ? await CenterService.GetAllCenters(): await CenterService.GetCentersExceptAdminSite();

            if (response?.status !== HTTPResponse.OK) {
                if (response.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else{
                    throw response.error;
                }
            }
            else{
                const { data } = response;
                setCenters(data);
            }
        } catch {
            setErrorOnSave(Abstractor_Msgs.CENTER_LOAD_ERROR)
        }
    };
    useEffect(() => {
        const GetCenters = async () => {
            ToggleLoader(true)
            await GetAllCenters();
            ToggleLoader(false)
        }
        GetCenters();
    }, [])
    const HandleFieldChange = (e) => {
        const { name, value, selectedOptions } = e.target; 
        switch (name) {
            case "firstName":
            case "lastName":
            case "emailAddress":
            case "centerId": {
                setAbstractorDetails((prev) => ({
                    ...prev,
                    [name]: value,
                }))
                if (name === "centerId")
                    setAbstractorDetails((prev) => ({
                        ...prev,
                        centerName: selectedOptions[0].text
                    }))
                if (name === "emailAddress") {
                    setErrors((prev) => ({
                        ...prev,
                        emailEmpty: false,
                        emailInvalid: false,
                    }))
                } else {
                    setErrors((prev) => ({
                        ...prev,
                        [name]: false,
                    }))
                }
                break;
            }
            default: break;
        }
    }
    const HandleSave = async (sendEmail) => {
        try { 
            if (!ValidateForm()) {
                return;
            }
            const payload = {
                ...abstractorDetails,
                editedBy: GetLoggedInUserID(),
                sendEmail: sendEmail
            }
            if (abstractorDetails.abstractorId === 0) {
                ToggleLoader(true);
                const response = await AbstractorService.AddAbstractor(payload);
                ToggleLoader(false);
                const { data } = response; 
                switch (response.status) {
                    case HTTPResponse.OK: {
                        if (data?.sendEmail) {
                            if (data?.sendEmailResult)
                                ShowToast(Abstractor_Msgs.EMAIL_SENDOUT_SUCCESS, ToastMessageType.Success);
                            else if (!data?.sendEmailResult)
                                ShowToast(Abstractor_Msgs.EMAIL_SENDOUT_ERROR, ToastMessageType.Success);
                        }
                        else
                            ShowToast(Abstractor_Msgs.ADD_SUCCESS, ToastMessageType.Success);

                        CloseModalPopUp(data?.abstractorId); break
                    }
                    case HTTPResponse.Conflict: {
                        setErrorOnSave(Abstractor_Msgs.EMAIL_EXISTS); break
                    }
                    case HTTPResponse.InternalServerError: {
                        setErrorOnSave(Abstractor_Msgs.SAVE_ABS); break
                    }
                    case HTTPResponse.Unauthorized: {
                        HandleSessionTimeout(); break;
                    }
                    default: break;
                }
            }
            else {
                ToggleLoader(true);
                const response = await AbstractorService.UpdateAbstractor(payload);
                ToggleLoader(false); 
                const { data } = response
                switch (response.status) {
                    case HTTPResponse.OK: {
                        if (data?.sendEmail) {
                            if (data?.sendEmailResult)
                                ShowToast(Abstractor_Msgs.EMAIL_SENDOUT_SUCCESS, ToastMessageType.Success);
                            else if (!data?.sendEmailResult)
                                ShowToast(Abstractor_Msgs.EMAIL_SENDOUT_ERROR, ToastMessageType.Success);
                        } else
                            ShowToast(Abstractor_Msgs.EDIT_SUCCESS, ToastMessageType.Success);
                        CloseModalPopUp(data?.abstractorId); break
                    }
                    case HTTPResponse.Conflict: {
                        setErrorOnSave(Abstractor_Msgs.EMAIL_EXISTS); break
                    }
                    case HTTPResponse.InternalServerError: {
                        setErrorOnSave(Abstractor_Msgs.EMAIL_EXISTS); break
                    }
                    case HTTPResponse.Unauthorized: {
                        HandleSessionTimeout(); break;
                    }
                    default: break;
                }
            }
        } catch (error) {
            setErrorOnSave(CommonError_Msgs.SAVE_ERR)
            //ShowToast(ToastMessages.Fail, ToastMessageType.Failed);
        }
    }
    const ValidateForm = () => {
        const { firstName, lastName, emailAddress, centerId } = abstractorDetails
        setErrorOnSave("")
        setErrors((prev) => ({
            ...prev,
            firstName: isStringEmpty(firstName),
            lastName: isStringEmpty(lastName),
            emailEmpty: isStringEmpty(emailAddress),
            emailInvalid: !isStringEmpty(emailAddress) && !IsEmailValid(emailAddress),
            centerId: Number(centerId) === 0, //Number("") returns 0 
        }))
        if (!isStringEmpty(firstName) && !isStringEmpty(lastName) && !isStringEmpty(emailAddress) &&
            IsEmailValid(emailAddress) && Number(centerId) !== 0)
            return true;
        else
            return false
    }
    return (
        <section className="edit-modal">
            <div
                className="modal"//show 
                id="AddAbstractorModal"
                tabIndex={-1}
                aria-labelledby="ModalFormLabel"
                aria-hidden="true"
                style={{ display: "block", paddingRight: "17px", backgroundColor: "#00000094" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container">
                                <div className="row modal-headerr">
                                    <div className="col-10 col-md-10 col-lg-10">
                                        <label htmlFor="">
                                            {pageAction == PageAction.add ? "Add New Abstractor" :
                                                pageAction == PageAction.edit ? "Edit Abstractor" : ""}
                                        </label>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 btn-align">
                                        <button
                                            type="button"
                                            className="btn-close btn-close-white"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={() => CloseModalPopUp(0)}
                                        />
                                    </div>
                                </div>
                                <div className="edit-modal-main">
                                    <div className="text-input-main">
                                        <div className="input-details">
                                            <div style={{ marginLeft: "-15px" }}>
                                                <ErrorField show={!isStringEmpty(errorOnSave)} message={errorOnSave} />
                                            </div>
                                            {pageAction === PageAction.edit && (
                                                <div className="row edit-center-name-div">
                                                    <div className="col-md-4 col-lg-4">
                                                        <label htmlFor="abstractorId">Abstractor ID: </label>
                                                    </div>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input
                                                            id="abstractorId"
                                                            name="abstractorId"
                                                            className="form-control common-text-input"
                                                            type="text"
                                                            readOnly={true}
                                                            defaultValue={abstractorDetails.abstractorId}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            <div className="row edit-center-name-div">
                                                <div className="col-md-4 col-lg-4">
                                                    <label htmlFor="firstName">First Name: </label>
                                                </div>
                                                <div className="col-md-8 col-lg-8">
                                                    <input
                                                        id="firstName"
                                                        name="firstName"
                                                        className="form-control common-text-input"
                                                        type="text"
                                                        placeholder="First name"
                                                        value={abstractorDetails.firstName}
                                                        onChange={HandleFieldChange}
                                                        maxLength={50}
                                                    /> <ErrorField show={errors.firstName} message={Abstractor_Msgs.FIRSTNAME} />

                                                </div>
                                            </div>
                                            <div className="row edit-center-name-div">
                                                <div className="col-md-4 col-lg-4">
                                                    <label htmlFor="lastName">Last Name: </label>
                                                </div>
                                                <div className="col-md-8 col-lg-8">
                                                    <input
                                                        id="lastName"
                                                        name="lastName"
                                                        className="form-control common-text-input"
                                                        type="text"
                                                        placeholder="Last name"
                                                        value={abstractorDetails.lastName}
                                                        onChange={HandleFieldChange}
                                                        maxLength={50}
                                                    /><ErrorField show={errors.lastName} message={Abstractor_Msgs.LASTNAME} />
                                                </div>

                                            </div>
                                            <div className="row edit-center-name-div">
                                                <div className="col-md-4 col-lg-4">
                                                    <label htmlFor="email">Email Address: </label>
                                                </div>
                                                <div className="col-md-8 col-lg-8">
                                                    <input
                                                        id="emailAddress"
                                                        name="emailAddress"
                                                        className="form-control common-text-input"
                                                        readOnly={abstractorDetails.abstractorId !== 0 && !isStringEmpty(String(abstractorDetails.abstractorId))
                                                            && abstractorDetails.abstractorId}
                                                        type="text"
                                                        placeholder="Email"
                                                        value={abstractorDetails.emailAddress}
                                                        onChange={HandleFieldChange}
                                                    />  <ErrorField show={errors.emailEmpty} message={Abstractor_Msgs.EMAIL_REQUIRED} />
                                                    <ErrorField show={!errors.emailEmpty && errors.emailInvalid} message={Abstractor_Msgs.EMAIL_VALID} />

                                                </div>
                                            </div>
                                            <div className="row edit-center-name-div">
                                                <div className="col-md-4 col-lg-4">
                                                    <label htmlFor="centerId">Center: </label>
                                                </div>
                                                <div className="col-md-8 col-lg-8">
                                                    <select
                                                        className="form-select common-text-input"
                                                        name="centerId"
                                                        id="centerId"
                                                        value={abstractorDetails.centerId}
                                                        onChange={HandleFieldChange}
                                                        disabled={abstractorDetails.abstractorId !== 0 && !isStringEmpty(String(abstractorDetails.abstractorId))
                                                            && abstractorDetails.abstractorId}
                                                    >
                                                        <option value={0}>Please select</option>
                                                        {centers?.map((c) => {
                                                            return (
                                                                <option key={c.centerId} value={c.centerId}>{c.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <ErrorField show={errors.centerId} message={Abstractor_Msgs.CENTER} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="save-btn-div">
                                            <button className="modal-save-btn" onClick={() => { HandleSave(false) }}>Save</button>
                                            <button className="modal-save-btn" onClick={() => { HandleSave(true) }}>
                                                Email login credentials
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // )

    );
};

export default AbstractorForm;
