import WebServiceWrapper from "./WebServiceWrapper";

export const PatientDemographicsService = {
  GetAllDemographics,
  SaveDemographic,
};

function GetAllDemographics(patientId) {
  debugger
  return WebServiceWrapper.Get(`/demographic/GetAllDemographicsData?patientId=${patientId}`);
}

function SaveDemographic(payload) {
    debugger    
  return WebServiceWrapper.PostWithHeader("/demographic/SaveDemographicData", payload);
}



