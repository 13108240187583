import React, { useContext, useEffect, useState } from "react";
import { DefaultDate, HTTPResponse, PageDesignConstants, SideMenuItems, ToastMessageType, ToastMessages } from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import DateControl from "../../Components/DateControl";
import PatientDetails from '../../Components/PatientInformation';
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import { CommonError_Msgs, CommonMsgs, HRU_MSGS } from "../../Helpers/HelperText";
import ErrorField from "../../Components/ErrorField";
import { AllowOnlyIntegers, GetDisplayDate, GetLocalStorageData, GetLoggedInUserID, GetOrdinal, IsAfterIndex, IsDateBefore, IsDateEmpty, IsDateValid, IsOnOrAfterDate, IsOnOrAfterIndex, ParseDate, SetDateState, ValueInRange, isStringEmpty } from "../../Helpers/Utilities";
import { HruService } from "../../WebApiServices/Hru.service";
import { AppContext } from "../../Contexts/AppContextProvider";
const HealthcareResourceUtilization = () => {

  const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout, appState } =
  useContext(AppContext);

  const { tmtAfterEpcoLOTCnt } = appState ?? 0;
  const navigatePaths = {
    prevPage: appState.showCartPage ? "/CARTCellTherapy" : tmtAfterEpcoLOTCnt == 0 ? "/Epcoritamab" : `/TreatmentsAfterEpcoritamab/${tmtAfterEpcoLOTCnt}`,
    currPage: "/HRU",
    nextPage: "/ValidateSubmit"
  }
  // const pid = GetLocalStorageData("patientId") ?? 0;
  const patientId = GetLocalStorageData("patientId");

  const [indexDate, setIndexDate] = useState("");
  const [lbclDate, setLbclDate] = useState("");
  const [chartAbstractionDate, setChartAbstractionDate] = useState("");
  const [death_Date, set_Death_Date] = useState("");

  const InitialSoftErrList = {
    q94HospitalizationUnk_err: false,
    q95ERvisitsUnk_err:false,
    q96LbclUnk_err:false,
    q97renalUnk_err:false,
    q98liverUnk_err:false,
  }

  const InitialHardErrList = {
    q94hospitalizationEmpty:false,
   // q94ValueTxtEmpty:false,
   // q94SizeRangeLimHard:false,

    q95ERvisitsEmpty:false,
    q95ERvisitsTxtEmpty:false,
   // q95SizeRangeLimHard:false,

    q96LbclEmpty:false,
    q96LbclTxtEmpty:false,
   // q96SizeRangeLimHard:false,

    q97renalEmpty:false,
    //q97renalTxtEmpty:false,
  //  q97SizeRangeLimHard:false,

    q98liverEmpty:false,
   // q98liverTxtEmpty:false,
  //  q98SizeRangeLimHard:false,
  }

  
  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const [hruDetails, setHruDetails] = useState({
//94    
    q94_hospitalization_txt:"",
    q94_hospitalization_Unk:null,

//95
    q95_ER_Visits_txt:"",
    q95_ER_Visits_Unk:null,

//96
    q96_outpatient_visits_txt:"",
    q96_outpatient_visits_Unk:null,

//97
    q97_renal_tests_txt:"",
    q97_renal_tests_Unk:null,

//98
    q98_liver_tests_txt:null, 
    q98_liver_tests_Unk:null,



  });



      //Outpatient Visits 
      const OutpatientTestRow = {
        hruoutpatientvisitid: 0, // pk of child table
    
        //Question 105 - 107
        outpatient_assess_dx: null,
        outpatient_assess_dx_date: DefaultDate,
    
        outpatientDxDateUnkSoft:false,
    
        outpatientLbclEmptyHard: false,
        outpatientAfter_dt_Ca_EmptyHard: false,
        outpatientDxEmptyHard :false,

//106
        healthcare_assess: null,
        healthcareOthTxt:"",
        healthcareEmptyHard: false,
        healthcareUnkSoft: false,
        healthcareOthTxtEmptyHard: false,
    
//107
        visit_reason: null,
        visitreasonOthTxt:"",
        visitreasonEmptyHard: false,
        visitreasonUnkSoft: false,
        visitreasonOthTxtEmptyHard: false

      }


    //Renal Function Test 
    const RenalTestRow = {
      hrurenalfunctionlabtestid: 0, // pk of child table
  
      //Question 108
      renal_assess_dx: null,
      renal_assess_dx_date: DefaultDate,
  
      renalDxDateUnkSoft:false,
  
      renalLbclEmptyHard: false,
      renalAfter_dt_Ca_EmptyHard: false,
      renalDxEmptyHard :false,
  
    }

    

//Liver Function Test
  const LiverTestRow = {
    hruliverfunctionlabtestid: 0, // pk of child table

    //Question 109
    liver_assess_dx: null,
    liver_assess_dx_date: DefaultDate,

    liverDxDateUnkSoft:false,

    liverLbclEmptyHard: false,
    liverAfter_dt_Ca_EmptyHard: false,
    liverDxEmptyHard :false,

  }


  const HospitalizationRow = {
    hruhospitalizationid: 0, // pk of child table

  //99
  hospital_admission: null,
  hospital_admission_date: DefaultDate,
  hospital_admissionDateUnkSoft:false,
  hospital_admissionDateEmptyHard:false,

  hospitalAdmissionLbclEmptyHard:false,
  hospitalAdmissionAfter_dt_Ca_EmptyHard:false,

//100
  hospital_discharge: null,
  hospital_discharge_date: DefaultDate,
  hospital_dischargeDateUnkSoft:false,
  hospital_dischargeDateEmptyHard:false,


  hospital_AfterAdmission:false,
  hospitalDischargeAfter_dt_Ca_EmptyHard:false,

  //101
  hospital_reason:null,
  hospital_reason_oth_txt:"",
  hospital_reasonUnksoft:false,
  hospital_reasonEmptyHard:false,
  hospital_reasontxtEmptyHard:false,


  //102
  hospital_discharge_diag: null,
  hospital_discharge_diagtxt:"",
  hospital_discharge_diagUnksoft:false,
  hospital_discharge_diagEmptyHard:false,
  hospital_discharge_diagtxtEmptyHard:false,
  }



const ERVisitsRow = {


  hruervisitsid : 0,// pk of child table
  //103
  emergency_visit:null,
  emergency_visit_date:DefaultDate,

  emergency_visitDateUnkSoft:false,
  emergency_visitDateEmptyHard:false,

  emergencyvisitLbclEmptyHard:false,
  ervisitAfter_dt_Ca_EmptyHard:false,

//104

primary_reason_visit:null,
primary_reason_visit_txt:"",

primary_reasonUnksoft:false,
primary_reasonEmptyHard:false,
primary_reasontxtEmptyHard:false,

}

  const [hospitalizationDetails, setHospitalizationDetails] = useState([HospitalizationRow]);
  const [erVisitsDetails, setERVisitsDetails] = useState([ERVisitsRow]);


  const [outpatientVisit, setOutpatientVisit] =  useState([OutpatientTestRow]);
  const [renalTests, setRenalTests] = useState([RenalTestRow]);
  const [liverTests, setLiverTests] = useState([ LiverTestRow]);


    const DeleteHospitalizationRow = (id) => {
      const newArray = [...hospitalizationDetails];
      newArray.splice(id, 1)
      setHospitalizationDetails(newArray);
  }
  const AddHospitalizationRow = () => {
      setHospitalizationDetails((prev) => [
          ...prev,
          {
              ...HospitalizationRow
          },
      ]);
  }


  const DeleteERVisitRow = (id) => {
    const newArray = [...erVisitsDetails];
    newArray.splice(id, 1)
    setERVisitsDetails(newArray);
}
const AddERVisitRow = () => {
    setERVisitsDetails((prev) => [
        ...prev,
        {
            ...ERVisitsRow
        },
    ]);
}
 
  const HandleDateChange = () => { }


  const DeleteOutpatientRow = (id) => {
    const newArray = [...outpatientVisit];
    newArray.splice(id, 1)
    setOutpatientVisit(newArray);
}
const AddOutpatientRow = () => {
    setOutpatientVisit((prev) => [
        ...prev,
        {
            ...OutpatientTestRow
        },
    ]);
}




const DeleteRenalRow = (id) => {
  const newArray = [...renalTests];
  newArray.splice(id, 1)
  setRenalTests(newArray);
}
const AddRenalRow = () => {
  setRenalTests((prev) => [
      ...prev,
      {
          ...RenalTestRow
      },
  ]);
}




const DeleteLiverRow = (id) => {
  const newArray = [...liverTests];
  newArray.splice(id, 1)
  setLiverTests(newArray);
}
const AddLiverRow = () => {
  setLiverTests((prev) => [
      ...prev,
      {
          ...LiverTestRow
      },
  ]);
}

  const HandleFieldChange = (e) => {
    const { name, id, value,checked } = e.target;
    debugger
    switch (name) {

  //94
      case "q94_hospitalization_Unk": { 
        setHruDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
           q94_hospitalization_txt:""
        }));


        setHospitalizationDetails([{
          ...HospitalizationRow,
          
          }])

        setSoftErrList((prev) => ({
          ...prev,
          q94HospitalizationUnk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q94hospitalizationEmpty: false,
         //  q94ValueTxtEmpty:false,
        }));
        break;
      }

      case "q94_hospitalization_txt": {
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
      }
        setHruDetails((prev) => ({
          ...prev,
          [name]: value,
          q94_hospitalization_Unk:null
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q94HospitalizationUnk_err: false,
        }));


        setHardErrList((prev) => ({
          ...prev,
          q94hospitalizationEmpty: false,
       //   q94ValueTxtEmpty:false,
          q94SizeRangeLimHard:false,
        }));

        break;
      }

      //95
      case "q95_ER_Visits_Unk": { 
        setHruDetails((prev) => ({
          ...prev,
          [name]: Number(value), //value,
           q95_ER_Visits_txt:"",
          //  emergency_visit:null
        }));

          setERVisitsDetails([{
          ...ERVisitsRow,

          }])

        setSoftErrList((prev) => ({
          ...prev,
          q95ERvisitsUnk_err: parseInt(value) === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q95ERvisitsEmpty: false,
          q95ERvisitsTxtEmpty:false,
        }));
        break;
      }

      case "q95_ER_Visits_txt": {
        if (value !== "" && !AllowOnlyIntegers(value)) {
          break;
      }
        setHruDetails((prev) => ({
          ...prev,
          [name]: value,
          q95_ER_Visits_Unk:null,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          q95ERvisitsUnk_err: false,
        }));


        setHardErrList((prev) => ({
          ...prev,
          q95ERvisitsEmpty: false,
          q95ERvisitsTxtEmpty:false,
        }));

        break;
      }


            //96
            case "q96_outpatient_visits_Unk": { 
              setHruDetails((prev) => ({
                ...prev,
                [name]: Number(value), //value,
                 q96_outpatient_visits_txt:""

              }));
      

              
        setOutpatientVisit([{
          ...OutpatientTestRow,
          
          }])
      
              setSoftErrList((prev) => ({
                ...prev,
                q96LbclUnk_err: parseInt(value) === 99,
                
              }));
      
              setHardErrList((prev) => ({
                ...prev,
                q96LbclEmpty: false,
                q96LbclTxtEmpty:false,
              }));
              break;
            }
     
            case "q96_outpatient_visits_txt": {
              if (value !== "" &&  !AllowOnlyIntegers(value)) {
                break;
            }
              setHruDetails((prev) => ({
                ...prev,
                [name]: value,
                q96_outpatient_visits_Unk:false,
              }));
      
              setSoftErrList((prev) => ({
                ...prev,
                q96LbclUnk_err: false,
              }));
      
      
              setHardErrList((prev) => ({
                ...prev,
                q96LbclEmpty: false,
                q96LbclTxtEmpty:false,
              }));
      
              break;
            }

            

                  //97
                  case "q97_renal_tests_Unk": { 
                    setHruDetails((prev) => ({
                      ...prev,
                      [name]: Number(value), //value,
                       q97_renal_tests_txt:""
                    }));
            
                    setRenalTests([{
                      ...RenalTestRow,
                      
                      }])


                    setSoftErrList((prev) => ({
                      ...prev,
                      q97renalUnk_err: parseInt(value) === 99,
                    }));
            
                    setHardErrList((prev) => ({
                      ...prev,
                      q97renalEmpty: false,
                     // q97renalTxtEmpty:false,
                    }));

                 
                    break;
                  }
           
                  case "q97_renal_tests_txt": {
                    if (value !== "" && !AllowOnlyIntegers(value)) {
                      break;
                  }
                    setHruDetails((prev) => ({
                      ...prev,
                      [name]: value,
                      q97_renal_tests_Unk:null,
                    }));
            
                    setSoftErrList((prev) => ({
                      ...prev,
                      q97renalUnk_err: false,
                    }));
            
            
                    setHardErrList((prev) => ({
                      ...prev,
                      q97renalEmpty: false,
                 
                    }));
            
           
                    break;
                  }

debugger
                  //98
                  case "q98_liver_tests_Unk": { 
                    setHruDetails((prev) => ({
                      ...prev,
                      [name]: Number(value), //value,
                       q98_liver_tests_txt:""
                    }));
            
                    setLiverTests([{
                      ...LiverTestRow,  
                      }])


                    setSoftErrList((prev) => ({
                      ...prev,
                      q98liverUnk_err: parseInt(value) === 99,
                    }));
            
                    setHardErrList((prev) => ({
                      ...prev,
                      q98liverEmpty: false,
                    //  q98liverTxtEmpty:false,


                      liverLbclEmptyHard: false,
                      liverAfter_dt_Ca_EmptyHard: false,
                      liverDxEmptyHard :false,

                    }));
                    
                    break;
                  }
          
                  case "q98_liver_tests_txt": {
                    if (value !== "" && !AllowOnlyIntegers(value)) {
                      break;
                  }

                  const intValue = Number(value);
                  const updatedLiverTests = Array.from({ length: intValue }, (_, i) => liverTests[i] || { date: '', datePart: '' });
                  setHruDetails((prev) => ({
                    ...prev,
                    [name]: intValue,
                    liverTests: updatedLiverTests,
                    q98_liver_tests_Unk: null,
                  }));


                 
                    // setHruDetails((prev) => ({
                    //   ...prev,
                    //   [name]: value,
                    //   q98_liver_tests_Unk:null
                    // }));
            
                    setSoftErrList((prev) => ({
                      ...prev,
                      q98liverUnk_err: false,
                    }));
            
            
                    setHardErrList((prev) => ({
                      ...prev,
                      q98liverEmpty: false,
                     // q98liverTxtEmpty:false,
                    }));
                    
                    break;
                  }
      default:
        break;
    }
  
  };

  const SavePage = async (validate) => {
    try {
      debugger
      ToggleLoader(true);
      setSoftErrList(InitialSoftErrList);
      setHardErrList(InitialHardErrList);

      // if (!ValidateForm()) {
      //   setShowTopErrMsg(true);
      //   ToggleLoader(false);
      //   return false;
      // }
      // setShowTopErrMsg(false);
      let isValid = validate ? ValidateForm() : true;




      if (hruDetails.q94_hospitalization_Unk === 1 && hospitalizationDetails && hospitalizationDetails.length > 0) {
       hospitalizationDetails.map(item => {
          if (!IsDateEmpty(item.hospital_admission_date) && !IsDateValid(item.hospital_admission_date)) {
            isValid = false;
          } else if (!IsDateEmpty(item.hospital_discharge_date) && !IsDateValid(item.hospital_discharge_date)) {
            isValid = false;
          }
      
          return item; 
        });
        
      }

//
if (hruDetails.q95_ER_Visits_Unk === 1 && erVisitsDetails && erVisitsDetails.length > 0) {
  erVisitsDetails.map(item => {
     if (!IsDateEmpty(item.emergency_visit_date) && !IsDateValid(item.emergency_visit_date)) {
       isValid = false;
     }
 
     return item; 
   });
   
 }

//
if (hruDetails.q96_outpatient_visits_Unk === 1 && outpatientVisit && outpatientVisit.length > 0) {
  outpatientVisit.map(item => {
     if (!IsDateEmpty(item.outpatient_assess_dx_date) && !IsDateValid(item.outpatient_assess_dx_date)) {
       isValid = false;
     }
 
     return item; 
   });
   
 }
//
if (hruDetails.q97_renal_tests_Unk === 1 && renalTests && renalTests.length > 0) {
  renalTests.map(item => {
     if (!IsDateEmpty(item.renal_assess_dx_date) && !IsDateValid(item.renal_assess_dx_date)) {
       isValid = false;
     }
 
     return item; 
   });
   
 }

//
if (hruDetails.q98_liver_tests_Unk=== 1 && liverTests && liverTests.length > 0) {
  liverTests.map(item => {
     if (!IsDateEmpty(item.liver_assess_dx_date) && !IsDateValid(item.liver_assess_dx_date)) {
       isValid = false;
     }
 
     return item; 
   });
   
 }


      

      if (isValid) {
        setShowTopErrMsg(false)
      const hospitalizationsData = hospitalizationDetails.map(test => ({
        hruhospitalizationid: test.hruhospitalizationid,

        hospitalAdmissionDate: test.hospital_admission === 1 && !IsDateEmpty(test.hospital_admission_date) ? ParseDate(test.hospital_admission_date) : null,
        hospitalAdmissionDateUnk: test.hospital_admission ===99?true:false,

        hospitalDischargeDate: test.hospital_discharge === 1 && !IsDateEmpty(test.hospital_discharge_date) ? ParseDate(test.hospital_discharge_date) : null,
        hospitalDischargeDateUnk: test.hospital_discharge ===99?true:false,

        primaryHospitalizationReason:test.hospital_reason,
        PrimaryHospitalizationReasonOthTxt: test.hospital_reason_oth_txt,

         dischargeDiagnosisTxt: test.hospital_discharge_diagtxt,
         dischargeDiagnosisNotavailable: test.hospital_discharge_diag === 2? true:false,
         dischargeDiagnosisUnk:test.hospital_discharge_diag === 99? true:false
      }));


     

     const erVisitData = erVisitsDetails.map(test => ({
      hruervisitsid: test.hruervisitsid,

      erVisitDate: test.emergency_visit=== 1  && !IsDateEmpty(test.emergency_visit_date)? ParseDate(test.emergency_visit_date) : null,
      erVisitDateUnk: test.emergency_visit === 99?true:false,


      primaryVisitReason:test.primary_reason_visit,
      primaryVisitReasonOthTxt: test.primary_reason_visit_txt,

 
    }));


    const outpatientVisitsData = outpatientVisit.map(test => ({
      hruoutpatientvisitid: test.hruoutpatientvisitid,

      outpatientVisitDate: test.outpatient_assess_dx === 1  && !IsDateEmpty(test.outpatient_assess_dx_date)? ParseDate(test.outpatient_assess_dx_date) : null,
      outpatientVisitDateUnk: test.outpatient_assess_dx === 99?true:false,


      healthcareProfessionalType:test.healthcare_assess,
      healthcareProfessionalTypeOthTxt: test.healthcareOthTxt,

      primaryVisitReason:test.visit_reason,
      primaryVisitReasonOthTxt: test.visitreasonOthTxt,
    }));



     
      //payload for Renal function test repeater
      const renalTestsData = renalTests.map(test => ({
        hrurenalfunctionlabtestid:test.hrurenalfunctionlabtestid,
        renalFunctionTestDate: test.renal_assess_dx === 1 && !IsDateEmpty(test.renal_assess_dx_date)? ParseDate(test.renal_assess_dx_date) : null,
        renalFunctionTestDateUnk: test.renal_assess_dx === 99?true:false,
    }));


      //payload for liver functional test repeater
      const liverTestsData = liverTests.map(test => ({
        hruliverfunctionlabtestid:test.hruliverfunctionlabtestid,
        liverFunctionTestDate: test.liver_assess_dx === 1  && !IsDateEmpty(test.liver_assess_dx_date)? ParseDate(test.liver_assess_dx_date) : null,
        //liverFunctionTestDate: test.liver_assess_dx === 1 && !IsDateEmpty(test.liver_assess_dx_date)? test.liver_assess_dx_date : null,
        liverFunctionTestDateUnk: test.liver_assess_dx === 99?true:false,
    }));
    console.log("RL",renalTestsData);
    console.log("LIVER",liverTestsData);

      const hruModel = {
        patientId: patientId,
        //94
        noOfHospitalization: hruDetails.q94_hospitalization_Unk,
      //  noOfHospitalizationUnk:hruDetails.q94_hospitalization_Unk === 99?true:false,

        //95
        noOfErVisits:hruDetails.q95_ER_Visits_Unk,
       // noOfErVisitsUnk:hruDetails.q95_ER_Visits_Unk === 99?true:false,

        //96
        noOfOutpatientVisits:hruDetails.q96_outpatient_visits_Unk,
       // noOfOutpatientVisitsUnk:hruDetails.q96_outpatient_visits_Unk === 99?true:false,

        //97
        noOfRenalfunctionTests:hruDetails.q97_renal_tests_Unk,
      //  noOfRenalfunctionTestsUnk:hruDetails.q97_renal_tests_Unk === 99?true:false,

        //98
        noOfLiverfunctionTests:hruDetails.q98_liver_tests_Unk,
        //noOfLiverfunctionTestsUnk:hruDetails.q98_liver_tests_Unk === 99 ?true:false,



        hospitalizationData: hospitalizationsData, //94 //99-102
        eRVisitData: erVisitData, // 103-104
        outpatientVisitsData : outpatientVisitsData, //105-107
        renalTest: renalTestsData, //97  //108
        liverTest: liverTestsData, //98  //109
       

        createdby: GetLoggedInUserID() ?? 0,
        editedby: GetLoggedInUserID() ?? 0,
        userId: GetLoggedInUserID(),
        isValidated: validate,
      };
      if (patientId > 0) {
        const response = await HruService.SaveHRUDetails(
          hruModel
        );
        ToggleLoader(false);
        if (response.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else throw response.error;
        } else{
          await LoadData();
          ToggleLoader(false);
         ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
        }
      }
      return true;
    }
    else{
      ToggleLoader(false);
      setShowTopErrMsg(true)
      return false;
    }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };



  useEffect(() => {
    async function FetchData() {
      if (!isNaN(patientId) && Number(patientId) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);



    const LoadData = async () => {

      try {
        if (patientId > 0) {
          const response = await HruService.GetAllHRUDetails(
            patientId
          );
          console.log("HRE load",response);
          if (response?.status === HTTPResponse.OK) {
            const { data } = response;
            setHruDetails((prev) => ({
              ...prev,
              userId: data.userId,

              q94_hospitalization_Unk: data.noOfHospitalization ,
           //   q94_hospitalization_Unk: data.noOfHospitalizationUnk ? 99 : null,

            //  q95_ER_Visits_txt: data.noOfErVisits ,
              q95_ER_Visits_Unk: data.noOfErVisits ,

            //  q96_outpatient_visits_txt: data.noOfOutpatientVisits ,
              q96_outpatient_visits_Unk: data.noOfOutpatientVisits,

            //  q97_renal_tests_txt: data.noOfRenalfunctionTests ,
              q97_renal_tests_Unk: data.noOfRenalfunctionTests ,

             // q98_liver_tests_txt: data.noOfLiverfunctionTests ,
              q98_liver_tests_Unk: data.noOfLiverfunctionTests,

            }));
          
            //#region hospitalization 
            if (
              data.hospitalizationData != null &&
              data.hospitalizationData.length > 0
            ) {
              const { hospitalizationData } = data;
              const hruhospitalizationDetailsArray = hospitalizationData.map((item, index) => {
                const hruhospitalizationid =
                  item.hruhospitalizationid != null
                    ? item.hruhospitalizationid
                    : 0;


                
                const hospital_admission_date = !isStringEmpty(
                  item.
                  hospitalAdmissionDate) 
                  ? 
                  item.hospitalAdmissionDate          
                  : DefaultDate;
  
                                
                  const hospital_discharge_date = !isStringEmpty(
                    item.
                    hospitalDischargeDate)
                    ? 
                    item.hospitalDischargeDate          
                    : DefaultDate;
    
    
                return {
                  ...HospitalizationRow,
                  hruhospitalizationid,
                  hospital_admission: item.hospitalAdmissionDateUnk
                    ? 99
                    : !isStringEmpty(item.hospitalAdmissionDate)
                    ? 1
                    : null,
                    hospital_admission_date,


                    hospital_discharge: item.hospitalDischargeDateUnk ? 99
                     : !isStringEmpty(item.hospitalDischargeDate)
                     ?1
                     :null,
                    hospital_discharge_date,
              
                    hospital_reason: !isStringEmpty(item.primaryHospitalizationReasonOthTxt) ? 5 : item.primaryHospitalizationReason,
                    hospital_reason_oth_txt: item.primaryHospitalizationReasonOthTxt !== "" ? item.primaryHospitalizationReasonOthTxt : "",
              
                    hospital_discharge_diag: !isStringEmpty(item.dischargeDiagnosisTxt) ? 1 :null,
                     hospital_discharge_diag : !isStringEmpty(item.dischargeDiagnosisTxt) 
                        ? 1 
                        : (item.dischargeDiagnosisNotavailable === true 
                            ? 2 
                            : (
                              item.dischargeDiagnosisUnk
                               === true ? 99 : null)),

                    hospital_discharge_diagtxt: item.dischargeDiagnosisTxt !=="" ? item.dischargeDiagnosisTxt:"",

                };
              });
              setHospitalizationDetails(hruhospitalizationDetailsArray);
            }
            //#endregion
//#region  ERVisit


if (
  data.erVisitData != null &&
  data.erVisitData.length > 0
) {
  const { erVisitData } = data;
  const hruervisitDetailsArray = erVisitData.map((item, index) => {

    const hruervisitsid =
      item.hruervisitsid != null
        ? item.hruervisitsid
        : 0;


    
    const emergency_visit_date = !isStringEmpty(
      item.
      erVisitDate)
      ? 
      item.erVisitDate          
      : DefaultDate;


    return {
      ...ERVisitsRow,
      hruervisitsid,
      emergency_visit: item.erVisitDateUnk
        ? 99
        : !isStringEmpty(item.erVisitDate)
        ? 1
        : null,
        emergency_visit_date,

  
         primary_reason_visit: !isStringEmpty(item.primaryVisitReasonOthTxt) ? 5 : item.primaryVisitReason,
         primary_reason_visit_txt: item.primaryVisitReasonOthTxt !== "" ? item.primaryVisitReasonOthTxt : "",
  
    };
  });
  setERVisitsDetails(hruervisitDetailsArray);
}


//#endregion


//#region outpatient 105-107


if (
  data.outpatientVisitsData != null &&
  data.outpatientVisitsData.length > 0
) {
  const { outpatientVisitsData } = data;
  const hruOutpatientDetailsArray = outpatientVisitsData.map((item, index) => {

    const hruoutpatientvisitid =
      item.hruoutpatientvisitid != null
        ? item.hruoutpatientvisitid
        : 0;


    
    const outpatient_assess_dx_date = !isStringEmpty(
      item.
      outpatientVisitDate)
      ? 
      item.outpatientVisitDate          
      : DefaultDate;


    return {
      ...OutpatientTestRow,
      hruoutpatientvisitid,

      outpatient_assess_dx: item.outpatientVisitDateUnk
        ? 99
        : !isStringEmpty(item.outpatientVisitDate)
        ? 1
        : null,
        outpatient_assess_dx_date,

  
        healthcare_assess: !isStringEmpty(item.healthcareProfessionalTypeOthTxt) ? 8 : item.healthcareProfessionalType,
        healthcareOthTxt: item.healthcareProfessionalTypeOthTxt !== "" ? item.healthcareProfessionalTypeOthTxt : "",


        
        visit_reason: !isStringEmpty(item.primaryVisitReasonOthTxt) ? 4 : item.primaryVisitReason,
        visitreasonOthTxt: item.primaryVisitReasonOthTxt !== "" ? item.primaryVisitReasonOthTxt : "",
  
    };
  });
  setOutpatientVisit(hruOutpatientDetailsArray);
}

//#endregion

            //#region Renal functional test
            debugger
            if (
              data.renalTest != null &&
              data.renalTest.length > 0
            ) {
              const { renalTest } = data;
              const hrurenalDetailsArray = renalTest.map((item, index) => {
                const hrurenalfunctionlabtestid =
                  item.hrurenalfunctionlabtestid != null
                    ? item.hrurenalfunctionlabtestid
                    : 0;
                const renal_assess_dx_date = !isStringEmpty(
                  item.renalFunctionTestDate
                )
                  ? 
                      item.renalFunctionTestDate          
                  : DefaultDate;
  
                
                return {
                  ...RenalTestRow,
                  hrurenalfunctionlabtestid,
                  renal_assess_dx: item.renalFunctionTestDateUnk
                    ? 99
                    : !isStringEmpty(item.renalFunctionTestDate)
                    ? 1
                    : null,
                 renal_assess_dx_date,
  
                };
              });
              setRenalTests(hrurenalDetailsArray);
            }

            //#endregion

            //#region Liver functional test
            if (
              data.liverTest != null &&
              data.liverTest.length > 0
            ) {
              const { liverTest } = data;
              const hruliverDetailsArray = liverTest.map((item, index) => {
                const hruliverfunctionlabtestid =
                  item.hruliverfunctionlabtestid != null
                    ? item.hruliverfunctionlabtestid
                    : 0;
                const liver_assess_dx_date = !isStringEmpty(
                  item.liverFunctionTestDate
                )
                  ? 
                      item.liverFunctionTestDate          
                  : DefaultDate;
  
                
                return {
                  // ...LiverTestRow,
                  ...LiverTestRow,
                  hruliverfunctionlabtestid,
                  liver_assess_dx: item.liverFunctionTestDateUnk
                    ? 99
                    : !isStringEmpty(item.liverFunctionTestDate)
                    ? 1
                    : null,
                 liver_assess_dx_date,
  
                };
              });
              setLiverTests(hruliverDetailsArray);
            }
//#endregion

debugger
            if (data.lbclDate) {
              console.log("lbcl Date HRU:", data.lbclDate);
              setLbclDate(data.lbclDate);
            }
  
            if (data.chartAbstractDate) {
              console.log("Chart HRU:", data.chartAbstractDate);
              setChartAbstractionDate(data.chartAbstractDate);
            }
  
            if (data.deathDate) {
              console.log("Death HRU:", data.deathDate);
              set_Death_Date(data.deathDate);
            }

            if(data.indexDate){
              console.log("Index date", data.indexDate)
              setIndexDate(data.indexDate);
            }
            
            
          } else if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          }
        }
      } catch (error) {
        ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
      }
    };





  const ValidateForm = () => {
    debugger
        let isValid = true;

        // Validation for q94
        if (hruDetails.q94_hospitalization_Unk === null ) {
          setHardErrList((prev) => ({
            ...prev,
            q94hospitalizationEmpty: true,
          }));
          isValid = false;
        }

  
                // Validation for q95
                if (hruDetails.q95_ER_Visits_Unk === null ) {
                  setHardErrList((prev) => ({
                    ...prev,
                    q95ERvisitsEmpty: true,
                  }));
                  isValid = false;
                }
   

        //Validation for 96
         if (hruDetails.q96_outpatient_visits_Unk === null ) {
          setHardErrList((prev) => ({
            ...prev,
            q96LbclEmpty: true,
          }));
          isValid = false;
        }



        //Validation for 97
        if (hruDetails.q97_renal_tests_Unk === null) {
          setHardErrList((prev) => ({
            ...prev,
            q97renalEmpty: true,
          }));
          isValid = false;
        }


        //Validation for 98
        if (hruDetails.q98_liver_tests_Unk === null) {
          setHardErrList((prev) => ({
            ...prev,
            q98liverEmpty: true,
          }));
          isValid = false;
        }




//repeater section question 94: 99-102

if (hruDetails.q94_hospitalization_Unk === 1 && hospitalizationDetails && hospitalizationDetails.length > 0) {
  const updatedArray = hospitalizationDetails.map(item => {

      let hospital_admissionDateEmptyHard = false;
      if (item.hospital_admission == null || (item.hospital_admission === 1 && IsDateEmpty(item.hospital_admission_date))) {
        hospital_admissionDateEmptyHard = true;
      }

      let hospitalAdmissionLbclEmptyHard = false;
      if(IsDateValid(item.hospital_admission_date) && IsDateValid(lbclDate) ){
    hospitalAdmissionLbclEmptyHard =
      !IsDateEmpty(item.hospital_admission_date) &&
      !IsDateEmpty(lbclDate) &&
      !IsOnOrAfterDate(item.hospital_admission_date, lbclDate);   
}

debugger
let hospitalAdmissionAfter_dt_Ca_EmptyHard =false;
if(IsDateValid(item.hospital_admission_date) && IsDateValid(death_Date) && IsDateValid(chartAbstractionDate)){
      hospitalAdmissionAfter_dt_Ca_EmptyHard =
      item.hospital_admission === 1 &&
      !IsDateEmpty(item.hospital_admission_date) &&
      (!IsDateEmpty(death_Date) ||
      !IsDateEmpty(chartAbstractionDate)) &&
      !IsDateBefore(
        item.hospital_admission_date,
        chartAbstractionDate,
        death_Date,
      );

      }

      let hospital_dischargeDateEmptyHard = false;
      if (item.hospital_discharge == null || (item.hospital_discharge === 1 && IsDateEmpty(item.hospital_discharge_date))) {
        hospital_dischargeDateEmptyHard = true;
      }


      let hospital_AfterAdmission= false;
      if(IsDateValid(item.hospital_discharge_date) && IsDateValid(item.hospital_admission_date) ){ 
       hospital_AfterAdmission =
      !IsDateEmpty(item.hospital_discharge_date) &&
      !IsDateEmpty(item.hospital_admission_date) &&
      !IsAfterIndex(item.hospital_discharge_date ,item.hospital_admission_date);
      }

      let hospitalDischargeAfter_dt_Ca_EmptyHard = false;
      if(IsDateValid(item.hospital_discharge_date) && IsDateValid(death_Date) && IsDateValid(chartAbstractionDate)){ 

      hospitalDischargeAfter_dt_Ca_EmptyHard =
      item.hospital_discharge === 1 &&
      !IsDateEmpty(item.hospital_discharge_date) &&
      (!IsDateEmpty(death_Date) ||
      !IsDateEmpty(chartAbstractionDate)) &&
      !IsDateBefore(
        item.hospital_discharge_date,
        chartAbstractionDate,
        death_Date,
      );
      }
      
      const hospital_reasonEmptyHard = item.hospital_reason === null;
      const hospital_reasontxtEmptyHard = hospital_reasonEmptyHard ? false : item.hospital_reason === 5 && isStringEmpty(item.hospital_reason_oth_txt);


      const hospital_discharge_diagEmptyHard = item.hospital_discharge_diag === null;
      const hospital_discharge_diagtxtEmptyHard = hospital_discharge_diagEmptyHard ? false : item.hospital_discharge_diag === 1 && isStringEmpty(item.hospital_discharge_diagtxt);



      if (hospital_admissionDateEmptyHard || hospital_reasonEmptyHard ||  (item.hospital_admission === 1 && !IsDateValid(item.hospital_admission_date))
       || (item.hospital_discharge === 1 && !IsDateValid(item.hospital_discharge_date))
       || hospital_reasonEmptyHard || hospital_reasontxtEmptyHard || hospital_discharge_diagEmptyHard || hospital_discharge_diagtxtEmptyHard 
       || hospitalAdmissionLbclEmptyHard || hospitalAdmissionAfter_dt_Ca_EmptyHard || hospital_AfterAdmission || hospitalDischargeAfter_dt_Ca_EmptyHard) {
          isValid = false;
      }
      return {
          ...item,
          hospital_admissionDateUnkSoft: false,
          hospital_admissionDateEmptyHard,

          hospital_dischargeDateUnkSoft:false,
          hospital_dischargeDateEmptyHard,

          hospitalAdmissionLbclEmptyHard,
          hospitalAdmissionAfter_dt_Ca_EmptyHard,

          hospital_reasonUnksoft: false,
          hospital_reasonEmptyHard,
          hospital_reasontxtEmptyHard,

          hospital_discharge_diagUnksoft:false,
          hospital_discharge_diagEmptyHard,
          hospital_discharge_diagtxtEmptyHard,

          hospital_AfterAdmission,
          hospitalDischargeAfter_dt_Ca_EmptyHard,
      };
  });
  setHospitalizationDetails(updatedArray);
}



// repeater emergency room 103-104

debugger
if (hruDetails.q95_ER_Visits_Unk === 1 && erVisitsDetails && erVisitsDetails.length > 0) {
  const updatedArray = erVisitsDetails.map(item => {

      let emergency_visitDateEmptyHard = false;
      if (item.emergency_visit == null || (item.emergency_visit === 1 && IsDateEmpty(item.emergency_visit_date))) {
        emergency_visitDateEmptyHard = true;
      }


      let emergencyvisitLbclEmptyHard = false;
      if(IsDateValid(item.emergency_visit_date) && IsDateValid(lbclDate) ){
      emergencyvisitLbclEmptyHard =
      !IsDateEmpty(item.emergency_visit_date) &&
      !IsDateEmpty(lbclDate) &&
      !IsOnOrAfterDate(item.emergency_visit_date, lbclDate);
      }

      let ervisitAfter_dt_Ca_EmptyHard = false;
      if(IsDateValid(item.emergency_visit_date) && IsDateValid(death_Date) && IsDateValid(chartAbstractionDate)){
        ervisitAfter_dt_Ca_EmptyHard=
        item.emergency_visit === 1 &&
      !IsDateEmpty(item.emergency_visit_date) &&
      (!IsDateEmpty(death_Date) ||
      !IsDateEmpty(chartAbstractionDate)) &&
      !IsDateBefore(
        item.emergency_visit_date,
        chartAbstractionDate,
        death_Date,
      );
      }
      const primary_reasonEmptyHard = item.primary_reason_visit == null;
      const primary_reasontxtEmptyHard = primary_reasonEmptyHard ? false : item.primary_reason_visit === 5 && isStringEmpty(item.primary_reason_visit_txt);


      if (emergency_visitDateEmptyHard ||  (item.emergency_visit === 1 && !IsDateValid(item.emergency_visit_date))
       || emergencyvisitLbclEmptyHard || ervisitAfter_dt_Ca_EmptyHard ) {
          isValid = false;
      }
      return {
          ...item,
          emergency_visitDateUnkSoft: false,
          emergency_visitDateEmptyHard,

          emergencyvisitLbclEmptyHard,
          ervisitAfter_dt_Ca_EmptyHard,

          primary_reasonUnksoft:false,
          primary_reasonEmptyHard,
          primary_reasontxtEmptyHard,
      };
  });
  setERVisitsDetails(updatedArray);
}




//105-108

if (hruDetails.q96_outpatient_visits_Unk === 1 && outpatientVisit && outpatientVisit.length > 0) {
  const updatedArray = outpatientVisit.map(item => {

      let outpatientDxEmptyHard = false;
      if (item.outpatient_assess_dx == null || (item.outpatient_assess_dx === 1 && IsDateEmpty(item.outpatient_assess_dx_date))) {
        outpatientDxEmptyHard = true;
      }


      let outpatientLbclEmptyHard = false;
      if(IsDateValid(item.outpatient_assess_dx_date) && IsDateValid(lbclDate) ){

      outpatientLbclEmptyHard =
      !IsDateEmpty(item.outpatient_assess_dx_date) &&
      !IsDateEmpty(lbclDate) &&
      !IsOnOrAfterDate(item.outpatient_assess_dx_date, lbclDate);

      }

      let outpatientAfter_dt_Ca_EmptyHard = false;
      if(IsDateValid(item.outpatient_assess_dx_date) && IsDateValid(death_Date) && IsDateValid(chartAbstractionDate)){

      outpatientAfter_dt_Ca_EmptyHard = 
      !IsDateEmpty(item.outpatient_assess_dx_date) &&
      (!IsDateEmpty(death_Date) ||
      !IsDateEmpty(chartAbstractionDate)) &&
      !IsDateBefore(
        item.outpatient_assess_dx_date,
        death_Date,
        chartAbstractionDate,
      );
      }
      const healthcareEmptyHard = item.healthcare_assess == null;
      const healthcareOthTxtEmptyHard = healthcareEmptyHard ? false : item.healthcare_assess === 8 && isStringEmpty(item.healthcareOthTxt);

      const visitreasonEmptyHard = item.visit_reason == null;
      const visitreasonOthTxtEmptyHard = visitreasonEmptyHard ? false : item.visit_reason === 4 && isStringEmpty(item.visitreasonOthTxt);


      if (outpatientDxEmptyHard ||  (item.outpatient_assess_dx === 1 && !IsDateValid(item.outpatient_assess_dx_date))
       || outpatientLbclEmptyHard || outpatientAfter_dt_Ca_EmptyHard || healthcareEmptyHard 
       || healthcareOthTxtEmptyHard || visitreasonEmptyHard || visitreasonOthTxtEmptyHard) {
          isValid = false;
      }
      return {
          ...item,
          outpatientDxDateUnkSoft:false,
          outpatientDxEmptyHard,

          outpatientLbclEmptyHard,
          outpatientAfter_dt_Ca_EmptyHard,

          healthcareUnkSoft: false,
          healthcareEmptyHard,
          healthcareOthTxtEmptyHard,

          visitreasonUnkSoft: false,
          visitreasonEmptyHard,
          visitreasonOthTxtEmptyHard
      };
  });
  setOutpatientVisit(updatedArray);
}





    //#region 108
if ( hruDetails.q97_renal_tests_Unk === 1 && renalTests && renalTests.length > 0) {
  const updatedArray = renalTests.map((item) => {
    let renalDxEmptyHard = false;
    if (
      item.renal_assess_dx == null ||
      (item.renal_assess_dx === 1 &&
        IsDateEmpty(item.renal_assess_dx_date))
    ) {
      renalDxEmptyHard = true;
    }

    let renalLbclEmptyHard = false; 
    if(IsDateValid(item.renal_assess_dx_date) && IsDateValid(lbclDate) ){
    renalLbclEmptyHard = 
      !IsDateEmpty(item.renal_assess_dx_date) &&
      !IsDateEmpty(lbclDate) &&
      !IsOnOrAfterDate(item.renal_assess_dx_date, lbclDate);
    }

      
    let renalAfter_dt_Ca_EmptyHard = false;
    if(IsDateValid(item.renal_assess_dx_date) && IsDateValid(death_Date) && IsDateValid(chartAbstractionDate)){

    renalAfter_dt_Ca_EmptyHard = 
      !IsDateEmpty(item.renal_assess_dx_date) &&
      !IsDateEmpty(death_Date) &&
      !IsDateEmpty(chartAbstractionDate) &&
      !IsDateBefore(
        item.renal_assess_dx_date,
        death_Date,
        chartAbstractionDate,
      );
      }

      if (
        renalDxEmptyHard ||
        (item.renal_assess_dx == 1 &&
          !IsDateValid(item.renal_assess_dx_date)) ||

          renalLbclEmptyHard ||
          renalAfter_dt_Ca_EmptyHard
      ) {
        isValid = false;
      }

    return {
      ...item,
      
      renalDxDateUnkSoft: false,
      renalDxEmptyHard,
      renalLbclEmptyHard,
      renalAfter_dt_Ca_EmptyHard,
    };
  });
  setRenalTests(updatedArray);
}
//#endregion




    //#region  repeater section liver functional test 109
    if (hruDetails.q98_liver_tests_Unk === 1 && liverTests && liverTests.length > 0) {
      const updatedArray = liverTests.map((item) => {
        let liverDxEmptyHard = false;
        if (
          item.liver_assess_dx == null ||
          (item.liver_assess_dx === 1 &&
            IsDateEmpty(item.liver_assess_dx_date))
        ) {
          liverDxEmptyHard = true;
        }

        let liverLbclEmptyHard = false;
        if(IsDateValid(item.liver_assess_dx_date) && IsDateValid(lbclDate) ){

        liverLbclEmptyHard = 
          !IsDateEmpty(item.liver_assess_dx_date) &&
          !IsDateEmpty(lbclDate) &&
          !IsOnOrAfterDate(item.liver_assess_dx_date, lbclDate);
        }

        let liverAfter_dt_Ca_EmptyHard = false;
        if(IsDateValid(item.liver_assess_dx_date) && IsDateValid(death_Date) && IsDateValid(chartAbstractionDate)){
        liverAfter_dt_Ca_EmptyHard =
          !IsDateEmpty(item.liver_assess_dx_date) &&
          !IsDateEmpty(death_Date) &&
          !IsDateEmpty(chartAbstractionDate) &&
          !IsDateBefore(
            item.liver_assess_dx_date,
            death_Date,
            chartAbstractionDate,
          );
          }
          if (
            liverDxEmptyHard ||
            (item.liver_assess_dx == 1 &&
              !IsDateValid(item.liver_assess_dx_date)) ||

              liverLbclEmptyHard ||
              liverAfter_dt_Ca_EmptyHard
          ) {
            isValid = false;
          }

        return {
          ...item,
          
          liverDxDateUnkSoft: false,
          liverDxEmptyHard,
          liverLbclEmptyHard,
          liverAfter_dt_Ca_EmptyHard,
        };
      });
      setLiverTests(updatedArray);
    }
    //#endregion

        return isValid;
      };
     


  //Repeater
  const HandleRepeaterDateChange = (name, date, datePart) => {
    debugger;
    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);
    switch (name) {
 
    //Hospitalization admission Datechange
    case "hospital_admission_date-" + id: {
      let updatedArray = [...hospitalizationDetails];
      const idx = name.slice(lastIndex + 1);
      updatedArray[id].hospital_admission_date = SetDateState(updatedArray[id].hospital_admission_date, date, datePart);
      updatedArray[id].hospital_admission = 1;

      updatedArray[id].hospital_admissionDateEmptyHard = false;
      updatedArray[id].hospital_admissionDateUnkSoft = false;
      updatedArray[id].hospital_admissionDateEmptyHard =false;
      setHospitalizationDetails(updatedArray);
  }
      break;

//Hospitalization discharge date
case "hospital_discharge_date-" + id: {
  let updatedArray = [...hospitalizationDetails];
  const idx = name.slice(lastIndex + 1);
  updatedArray[id].hospital_discharge_date = SetDateState(updatedArray[id].hospital_discharge_date, date, datePart);
  updatedArray[id].hospital_discharge = 1;

  updatedArray[id].hospital_dischargeDateEmptyHard = false;
  updatedArray[id].hospital_dischargeDateUnkSoft = false;
  setHospitalizationDetails(updatedArray);
}
  break;


//ERVISITS

case "emergency_visit_date-" + id: {
  let updatedArray = [...erVisitsDetails];
  const idx = name.slice(lastIndex + 1);
  updatedArray[id].emergency_visit_date = SetDateState(updatedArray[id].emergency_visit_date, date, datePart);
  updatedArray[id].emergency_visit = 1;

  updatedArray[id].emergency_visitDateEmptyHard = false;
  updatedArray[id].emergency_visitDateUnkSoft = false;
  setERVisitsDetails(updatedArray);
}
  break;



// outpatient Visit Datechange
case "outpatient_assess_dx_date-" + id:
  {
    let updatedArray = [...outpatientVisit];
    const idx = name.slice(lastIndex + 1);
    updatedArray[id].outpatient_assess_dx_date = SetDateState(
      updatedArray[id].outpatient_assess_dx_date,
      date,
      datePart
    );
    updatedArray[id].outpatient_assess_dx = 1;

    updatedArray[id].outpatientDxDateUnkSoft = false;
    updatedArray[id].outpatientDxEmptyHard = false;
    setOutpatientVisit(updatedArray);
  }
  break;




// Renal Functional Test Datechange

case "renal_dx_date-" + id:
  {
    let updatedArray = [...renalTests];
    const idx = name.slice(lastIndex + 1);
    updatedArray[id].renal_assess_dx_date = SetDateState(
      updatedArray[id].renal_assess_dx_date,
      date,
      datePart
    );
    updatedArray[id].renal_assess_dx = 1;

    updatedArray[id].renalDxDateUnkSoft = false;
    updatedArray[id].renalDxEmptyHard = false;
    setRenalTests(updatedArray);
  }
  break;


// Liver Functional Test DateChange

      case "liver_dx_date-" + id:
        {
          let updatedArray = [...liverTests];
          const idx = name.slice(lastIndex + 1);
          updatedArray[id].liver_assess_dx_date = SetDateState(
            updatedArray[id].liver_assess_dx_date,
            date,
            datePart
          );
          updatedArray[id].liver_assess_dx = 1;

          updatedArray[id].liverDxDateUnkSoft = false;
          updatedArray[id].liverDxEmptyHard = false;
          setLiverTests(updatedArray);
        }
        break;
      default:
        break;
    }
  };





  const HandleRepeaterFieldChange = (e) => {
    debugger;
    const { name, id, value } = e.target;
    const value_int = Number(value);
    const index = Number(id);
    switch (name) {

// Question 99

case "hospital_admission" + id: {
  let updatedArray = [...hospitalizationDetails];
  updatedArray[id].hospital_admission = value_int;
  updatedArray[id].hospital_admission_date = DefaultDate;

  updatedArray[id].hospital_admissionDateUnkSoft = value_int === 99;
  updatedArray[id].hospital_admissionDateEmptyHard = false;
  updatedArray[id].hospitalAdmissionLbclEmptyHard = false;
  updatedArray[id].hospitalAdmissionAfter_dt_Ca_EmptyHard = false;
  setHospitalizationDetails(updatedArray);
  break;
}

case "hospital_discharge" + id: {
  let updatedArray = [...hospitalizationDetails];
  updatedArray[id].hospital_discharge = value_int;
  updatedArray[id].hospital_discharge_date = DefaultDate;

  updatedArray[id].hospital_dischargeDateUnkSoft = value_int === 99;
  updatedArray[id].hospital_dischargeDateEmptyHard = false;
  updatedArray[id].hospital_AfterAdmission =false;
  updatedArray[id].hospitalDischargeAfter_dt_Ca_EmptyHard =false;
  setHospitalizationDetails(updatedArray);
  break;
}


//101

case "hospital_reason" + id: {
  let updatedArray = [...hospitalizationDetails];
  updatedArray[id].hospital_reason = value_int;
  updatedArray[id].hospital_reason_oth_txt = "";

  updatedArray[id].hospital_reasonUnksoft = value_int === 99;
  updatedArray[id].hospital_reasonEmptyHard = false;
  updatedArray[id].hospital_reasontxtEmptyHard = false;
  setHospitalizationDetails(updatedArray);
  break;
}
case "hospital_reason_oth_txt": {
  let updatedArray = [...hospitalizationDetails];
  updatedArray[id].hospital_reason = 5;
  updatedArray[id].hospital_reason_oth_txt = value;

  updatedArray[id].hospital_reasonUnksoft = value_int === 99;
  updatedArray[id].hospital_reasonEmptyHard = false;
  updatedArray[id].hospital_reasontxtEmptyHard = false;
  setHospitalizationDetails(updatedArray);
  break;
}

//102 -- hospital_discharge_diagtxt

case "hospital_discharge_diag" + id: {
  let updatedArray = [...hospitalizationDetails];
  updatedArray[id].hospital_discharge_diag = value_int;
  updatedArray[id].hospital_discharge_diagtxt = "";

  updatedArray[id].hospital_discharge_diagUnksoft = value_int === 99;
  updatedArray[id].hospital_discharge_diagEmptyHard = false;
  updatedArray[id].hospital_discharge_diagtxtEmptyHard = false;
  setHospitalizationDetails(updatedArray);
  break;
}

case "hospital_discharge_diagtxt": {
  let updatedArray = [...hospitalizationDetails];
  updatedArray[id].hospital_discharge_diag = 1;
  updatedArray[id].hospital_discharge_diagtxt = value;

  updatedArray[id].hospital_discharge_diagUnksoft = value_int === 99;
  updatedArray[id].hospital_discharge_diagEmptyHard = false;
  updatedArray[id].hospital_discharge_diagtxtEmptyHard = false;
  setHospitalizationDetails(updatedArray);
  break;
}





//103-104 ER VISITS

//103
case "emergency_visit" + id: {
  let updatedArray = [...erVisitsDetails];
  updatedArray[id].emergency_visit = value_int;
  updatedArray[id].emergency_visit_date = DefaultDate;

  updatedArray[id].emergency_visitDateUnkSoft = value_int === 99;
  updatedArray[id].emergency_visitDateEmptyHard = false;
  updatedArray[id].emergencyvisitLbclEmptyHard =false;
  updatedArray[id].ervisitAfter_dt_Ca_EmptyHard =false;
  setERVisitsDetails(updatedArray);
  break;
}

//104
case "primary_reason_visit" + id: {
  let updatedArray = [...erVisitsDetails];
  updatedArray[id].primary_reason_visit = value_int;
  updatedArray[id].primary_reason_visit_txt = "";

  updatedArray[id].primary_reasonUnksoft = value_int === 99;
  updatedArray[id].primary_reasonEmptyHard = false;
  updatedArray[id].primary_reasontxtEmptyHard = false;
  setERVisitsDetails(updatedArray);
  break;
}


case "primary_reason_visit_txt": {
  let updatedArray = [...erVisitsDetails];
  updatedArray[id].primary_reason_visit = 5;
  updatedArray[id].primary_reason_visit_txt = value;

  updatedArray[id].primary_reasonUnksoft = value_int === 99;
  updatedArray[id].primary_reasonEmptyHard = false;
  updatedArray[id].primary_reasontxtEmptyHard = false;
  setERVisitsDetails(updatedArray);
  break;
}



      //105-107 Repeater 

      //105
      case "outpatient_assess_dx" + id: {
        let updatedArray = [...outpatientVisit];
        updatedArray[index].outpatient_assess_dx = value_int;
        updatedArray[index].outpatient_assess_dx_date = DefaultDate;

        updatedArray[index].outpatientDxDateUnkSoft = value_int === 99?true:false;
        updatedArray[index].outpatientLbclEmptyHard = false;
        updatedArray[index].outpatientAfter_dt_Ca_EmptyHard = false;
        updatedArray[index].outpatientDxEmptyHard = false;
        setOutpatientVisit(updatedArray);
        break;
      }

      //106
      case "healthcare_assess" + id: {
        let updatedArray = [...outpatientVisit];
        updatedArray[id].healthcare_assess = value_int;
        updatedArray[id].healthcareOthTxt = "";

        updatedArray[id].healthcareUnkSoft = value_int === 99;
        updatedArray[id].healthcareEmptyHard = false;
        updatedArray[id].healthcareOthTxtEmptyHard =false;
        setOutpatientVisit(updatedArray);
        break;
      }

      case "healthcareOthTxt": {
        let updatedArray = [...outpatientVisit];
        updatedArray[id].healthcare_assess = 8;
        updatedArray[id].healthcareOthTxt = value;

        updatedArray[id].healthcareUnkSoft = value_int === 99;
        updatedArray[id].healthcareEmptyHard = false;
        updatedArray[id].healthcareOthTxtEmptyHard = false;
        setOutpatientVisit(updatedArray);
        break;
      }

        //107
        case "visit_reason" + id: {
          let updatedArray = [...outpatientVisit];
          updatedArray[id].visit_reason = value_int;
          updatedArray[id].visitreasonOthTxt = "";

          updatedArray[id].visitreasonUnkSoft = value_int === 99;
          updatedArray[id].visitreasonEmptyHard = false;
          updatedArray[id].visitreasonOthTxtEmptyHard = false;
          setOutpatientVisit(updatedArray);
          break;
        }

        case "visitreasonOthTxt": {
          let updatedArray = [...outpatientVisit];
          updatedArray[id].visit_reason = 4;
          updatedArray[id].visitreasonOthTxt = value;

          updatedArray[id].visitreasonUnkSoft = value_int === 99;
          updatedArray[id].visitreasonEmptyHard = false;
          updatedArray[id].visitreasonOthTxtEmptyHard = false;
          setOutpatientVisit(updatedArray);
          break;
        }



      //108 Renal Functional Test
      case "renal_assess_dx" + id: {
        let updatedArray = [...renalTests];
        updatedArray[index].renal_assess_dx = value_int;
        updatedArray[index].renal_assess_dx_date = DefaultDate;

        updatedArray[index].renalDxDateUnkSoft = value_int === 99?true:false;
        updatedArray[index].renalDxEmptyHard = false;
        updatedArray[index].renalAfter_dt_Ca_EmptyHard = false;
        updatedArray[index].renalLbclEmptyHard = false;
        setRenalTests(updatedArray);
        break;
      }


      //109 Liver Functional Test
      case "liver_assess_dx" + id: {
        let updatedArray = [...liverTests];
        updatedArray[index].liver_assess_dx = value_int;
        updatedArray[index].liver_assess_dx_date = DefaultDate;

        updatedArray[index].liverDxDateUnkSoft = value_int === 99?true:false;
        updatedArray[index].liverDxEmptyHard = false;
        updatedArray[index].liverAfter_dt_Ca_EmptyHard = false;
        updatedArray[index].liverLbclEmptyHard = false;
        setLiverTests(updatedArray);
        break;
      }


      default:
        break;
    }
  };



  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>

      <section className="main-survey-section">
        <div className="row">
          <SideBarMenu activePageId={SideMenuItems.HRU} />

          <div className="col-md-9 col-pad-left">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>HEALTHCARE RESOURCE UTILIZATION (HRU) </h4>
              </div>
           {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div style={PageDesignConstants.PageDescriptionStyle}>
                    <i className="instruction-text-font">
                      In this section, you will be asked to enter information on the patient’s LBCL-related hospitalizations,
                      emergency room (ER) and outpatient visits from the index date, {""}
                      <span
                        style={{ color: PageDesignConstants.fontColor }}
                      >
                        {indexDate}
                      </span>
                      , through the date of chart abstraction. You will also be asked to provide reasons for the
                      patient’s visit and the respective diagnosis code, if applicable.
                      If there are multiple visits, you will be asked to provide this information for all visits.
                    </i>
                  </div>
                  <div className="question-answer">

                    <div className="vertical-pad-15">
                      <span className="text-spacing">
                        <b> Hospitalizations{" "}</b>
                      </span>
                    </div>
                    <div className="question-bot-sapce" style={{paddingBottom:"10px"}}>
                      <div className="question question-weight">
                        <span>Q94.</span>
                        <span className="quest-text-pad">
                         Does the patient have any hospitalizations related to LBCL? {""}
                        </span>
                      </div>
                      <div className="double-dig-answer">
                      <div className="">
                      <label>
                          <input
                            type="radio"
                            id="q94hospitalization_1"
                            name="q94_hospitalization_Unk"
                            value={1}                     
                          checked={hruDetails.q94_hospitalization_Unk === 1}
                            onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q94hospitalization_0"
                            name="q94_hospitalization_Unk"
                            value={0}
                            checked={hruDetails.q94_hospitalization_Unk === 0}
                            onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">
                              No
                            </span>
                          </label>
                        </div>

                      <div className="option-without-input">
                        <label >
                          <input type="radio"
                            id="q94_Unk_select_99"
                            name="q94_hospitalization_Unk"
                            value={99}
                            checked={ hruDetails.q94_hospitalization_Unk === 99}
                          onChange={HandleFieldChange} 
                          />
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                      </div>
                      </div>
                      <ErrorField show={hardErrList.q94hospitalizationEmpty} message={HRU_MSGS.q94hospitalizationEmpty} />
                      {/* <ErrorField show={hardErrList.q94ValueTxtEmpty} message={HRU_MSGS.q94ValueTxtEmpty} /> */}
                      <ErrorField show={softErrList.q94HospitalizationUnk_err} message={CommonMsgs.Unknown} />
                      {/* <ErrorField show={hardErrList.q94SizeRangeLimHard} message={HRU_MSGS.RangeLimit} /> */}

                    </div>                    
                    {/* Q99-102 Set1 start */}

                    {hruDetails.q94_hospitalization_Unk === 1 &&
                    <div className="dependent-section">   
                      {
                      hospitalizationDetails && hospitalizationDetails.length > 0 && (
                        hospitalizationDetails.map((data, idx) => (
                    

                      <div className="loop-section-ul">
                        <div className="loop-head">
                          <span className="question-weight">{GetOrdinal(idx + 1)} Hospitalization</span>
                          {idx != 0 && <div className="delete-btn-div">
                            <img
                             width="16px"
                             src="../Assets/images/Icon-material-delete.png"
                            alt=""
                            onClick={() => DeleteHospitalizationRow(idx)}
                            />
                            </div>}
                        </div>

                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q99.</span>
                            <span className="quest-text-pad">
                              Hospital admission date
                            </span>
                          </div>
                          <div className="double-dig-answer">

                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id={idx}
                                 name={"hospital_admission" + idx}
                                  value={1}
                                  checked={data.hospital_admission === 1}
                                   onChange={HandleRepeaterFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                   ctrlId={"hospital_admission_date-" + idx}
                                   HandleDateChange={HandleRepeaterDateChange}
                                   date={data.hospital_admission_date}
                                   removeErrMsgPadding={true}
                                   hideUnk = {true}

                                />
                              </span>
                            </div>
                            <div className="">
                            <label>
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_admission" + idx}
                                value={99}
                                checked={data.hospital_admission === 99}
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">Unknown </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField show={data.hospital_admissionDateUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={data.hospital_admissionDateEmptyHard} message={HRU_MSGS.hospitaladmissionDateEmpty} />
                          
                          <ErrorField show={data.hospitalAdmissionLbclEmptyHard} message={HRU_MSGS.Hosp_OnAfter_index_dt} />
                          <ErrorField show={data.hospitalAdmissionAfter_dt_Ca_EmptyHard} message={HRU_MSGS.Hosp_con2} />
                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q100.</span>
                            <span className="quest-text-pad">
                              Hospital discharge date
                            </span>
                          </div>
                          <div className="double-dig-answer">

                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id={idx}
                                 name={"hospital_discharge" + idx}
                                  value={1}
                                  checked={data.hospital_discharge === 1}
                                   onChange={HandleRepeaterFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                  ctrlId={"hospital_discharge_date-" + idx}
                                  HandleDateChange={HandleRepeaterDateChange}
                                  date={data.hospital_discharge_date}
                                  removeErrMsgPadding={true}
                                  hideUnk = {true}
                                />
                              </span>
                            </div>

                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_discharge" + idx}
                                value={99}
                                checked={data.hospital_discharge=== 99}
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">Unknown </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField show={data.hospital_dischargeDateUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={data.hospital_dischargeDateEmptyHard} message={HRU_MSGS.hospitaldischargeDateEmpty} />


                          <ErrorField show={data.hospital_AfterAdmission} message={HRU_MSGS.AfterAdmission} />
                          <ErrorField show={data.hospitalDischargeAfter_dt_Ca_EmptyHard} message={HRU_MSGS.condition_2_dis} />
                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q101.</span>
                            <span className="quest-text-pad">
                              Primary reason for hospitalization
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="">
                            <label>
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_reason" + idx}
                                value={1}
                                checked={data.hospital_reason=== 1}
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">
                                  LBCL symptoms
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_reason" + idx}
                                value={2}
                                checked={data.hospital_reason=== 2}
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">
                                  Disease progression
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_reason" + idx}
                                value={3}
                                checked={data.hospital_reason=== 3}
                                onChange={HandleRepeaterFieldChange}
                              />
                                <span className="radio-text-padding">
                                  Treatment toxicity/intolerability
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_reason" + idx}
                                value={4}
                                checked={data.hospital_reason=== 4}
                                onChange={HandleRepeaterFieldChange}
                              />
                             
                                <span className="radio-text-padding">
                                  Comorbidities
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label>
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_reason" + idx}
                                value={5}
                                checked={data.hospital_reason=== 5}
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">
                                  Other, specify:{" "}
                                  <input
                                   name={"hospital_reason_oth_txt"}
                                    className="input-dash"
                                    type="text"
                                    value={data.hospital_reason_oth_txt}
                                    onChange={HandleRepeaterFieldChange}
                                    maxLength={100}
                                    id={idx}                                               
                                  />
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label>
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_reason" + idx}
                                value={99}
                                checked={data.hospital_reason=== 99}
                                onChange={HandleRepeaterFieldChange}
                              />                              
                                <span className="radio-text-padding">
                                  Unknown{" "}
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField show={data.hospital_reasonUnksoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={data.hospital_reasonEmptyHard} message={HRU_MSGS.hospitalreasonEmpty} />
                          <ErrorField show={data.hospital_reasontxtEmptyHard} message={HRU_MSGS.hospitalreasonTxtEmpty} />


                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q102.</span>
                            <span className="quest-text-pad">
                              Discharge diagnosis (if available)
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_discharge_diag" + idx}
                                value={1}
                                checked={data.hospital_discharge_diag=== 1}  
                                onChange={HandleRepeaterFieldChange}
                              />
                                <span className="radio-text-padding">
                                  <input
                                    name={"hospital_discharge_diagtxt"}
                                    className="input-dash"
                                    type="text"
                                    value={data.hospital_discharge_diagtxt} 
                                    onChange={HandleRepeaterFieldChange}
                                    maxLength={100}
                                    id={idx}   
                                  /> </span>
                              </label>
                            </div>
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_discharge_diag" + idx}
                                value={2}
                                checked={data.hospital_discharge_diag=== 2}  
                                onChange={HandleRepeaterFieldChange}
                              />
                                <span className="radio-text-padding">
                                  None available{" "}
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"hospital_discharge_diag" + idx}
                                value={99}
                                checked={data.hospital_discharge_diag=== 99}  
                                onChange={HandleRepeaterFieldChange}
                              />          
                              <span className="radio-text-padding">Unknown </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField show={data.hospital_discharge_diagUnksoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={data.hospital_discharge_diagEmptyHard} message={HRU_MSGS.hospital_dis_diagEmpty} />
                          <ErrorField show={data.hospital_discharge_diagtxtEmptyHard} message={HRU_MSGS.hospitalreasonTxtEmpty} />
                        </div>
                      </div>
                          ))) 
                          }
                 
                 {hospitalizationDetails && hospitalizationDetails.length < 100 &&
                      <div className="sub-question-bot-sapce-ul mt-2">
                        <div className="answer-list-text" onClick={AddHospitalizationRow}>
                            <a>
                              <img width="18px" src="../Assets/images/plus.png" alt="" />
                            </a>
                            <a className="radio-text-padding">
                              Click here to add another Hospitalization
                            </a>
                        </div>
                      </div>
                    }
                    </div>
                    }


                    {/* Q99-102 Set2 end */}
                    <div className="vertical-pad-15">
                      <span className="text-spacing">
                        <b>  Emergency room (ER) visits{" "}</b>
                      </span>
                    </div>
                    <div className="question-bot-sapce" style={{paddingBottom:"10px"}}>
                      <div className="question question-weight">
                        <span>Q95.</span>
                        <span className="quest-text-pad">
                        Does the patient have any ER visits related to LBCL? {""}
                        </span>
                      </div>
                      <div className="double-dig-answer">

                      <div className="">
                      <label>
                          <input
                            type="radio"
                            id="q95Unk"
                            name="q95_ER_Visits_Unk"
                            value={1}
                            checked={ hruDetails.q95_ER_Visits_Unk === 1}
                          onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q95Unk"
                            name="q95_ER_Visits_Unk"
                            value={0}
                            checked={ hruDetails.q95_ER_Visits_Unk === 0}
                          onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">
                              No
                            </span>
                          </label>
                        </div>

                        <div className="option-without-input">
                        <label >
                          <input type="radio"
                            id="q95Unk"
                            name="q95_ER_Visits_Unk"
                            value={99}
                            checked={ hruDetails.q95_ER_Visits_Unk === 99}
                          onChange={HandleFieldChange}
                          />
                          
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q95ERvisitsEmpty} message={HRU_MSGS.q95ERvisitsEmpty} />
                      <ErrorField show={softErrList.q95ERvisitsUnk_err} message={CommonMsgs.Unknown} />
                      {/* <ErrorField show={hardErrList.q95ERvisitsTxtEmpty} message={HRU_MSGS.q95ERvisitsTxtEmpty} /> */}
                      {/* <ErrorField show={hardErrList.q95SizeRangeLimHard} message={HRU_MSGS.RangeLimit} /> */}
                    </div>   
                    
                                     
                    {/* Q103-104 Set1 start */}
                    {hruDetails.q95_ER_Visits_Unk === 1 &&
                    <div className="dependent-section">
                      {
                        erVisitsDetails && erVisitsDetails.length > 0 && (
                          erVisitsDetails.map((data, idx) => (
                                            

                                                  
                      <div className="loop-section-ul">
                        <div className="loop-head">
                          <span className="question-weight">{GetOrdinal(idx + 1)} ER Visit</span>
                          {idx != 0 && <div className="delete-btn-div">
                            <img
                             width="16px"
                             src="../Assets/images/Icon-material-delete.png"
                            alt=""
                            onClick={() => DeleteERVisitRow(idx)}
                            />
                            </div>}
                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q103.</span>
                            <span className="quest-text-pad">ER visit date</span>
                          </div>
                          <div className="double-dig-answer">

                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id={idx}
                                 name={"emergency_visit" + idx}
                                  value={1}
                                  checked={data.emergency_visit === 1}
                                   onChange={HandleRepeaterFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                  ctrlId={"emergency_visit_date-" + idx}
                                  HandleDateChange={HandleRepeaterDateChange}
                                  date={data.emergency_visit_date}
                                  removeErrMsgPadding={true}
                                  hideUnk = {true}
                                />
                              </span>
                            </div>
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"emergency_visit" + idx}
                                value={99}
                                checked={data.emergency_visit=== 99}
                                onChange={HandleRepeaterFieldChange}
                              />
                             
                                <span className="radio-text-padding">Unknown </span>
                              </label>
                            </div>
                          </div>
                          
                          <ErrorField show={data.emergency_visitDateUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={data.emergency_visitDateEmptyHard} message={HRU_MSGS.emergencyvisitDateEmpty} />



                          <ErrorField show={data.emergencyvisitLbclEmptyHard} message={HRU_MSGS.ERLbcl} />
                          <ErrorField show={data.ervisitAfter_dt_Ca_EmptyHard} message={HRU_MSGS.ervisitafterdtca} />


                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q104.</span>
                            <span className="quest-text-pad">
                              Primary reason for visit
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"primary_reason_visit" + idx}
                                value={1}
                                checked={data.primary_reason_visit=== 1}  
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">
                                  LBCL symptoms
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"primary_reason_visit" + idx}
                                value={2}
                                checked={data.primary_reason_visit=== 2}  
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">
                                  Disease progression
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label>
                              <input
                              type="radio"
                                id={idx}
                                name={"primary_reason_visit" + idx}
                                value={3}
                                checked={data.primary_reason_visit=== 3}  
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">
                                  Treatment toxicity/intolerability
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label>
                              <input
                                type="radio"
                                id={idx}
                                name={"primary_reason_visit" + idx}
                                value={4}
                                checked={data.primary_reason_visit=== 4}  
                                onChange={HandleRepeaterFieldChange}
                              />
                           
                                <span className="radio-text-padding">
                                  Comorbidities
                                </span>
                              </label>
                            </div>
                            <div className="">
                            <label >

                              <input
                                type="radio"
                                id={idx}
                                name={"primary_reason_visit" + idx}
                                value={5}
                                checked={data.primary_reason_visit=== 5}  
                                onChange={HandleRepeaterFieldChange}
                              />
                                <span className="radio-text-padding">
                                  Other, specify:{" "}
                                  <input
                                    name={"primary_reason_visit_txt"}
                                    className="input-dash"
                                    type="text"
                                    value={data.primary_reason_visit_txt} 
                                    onChange={HandleRepeaterFieldChange}
                                    maxLength={100}
                                    id={idx}
                                  />
                                </span>
                              </label>
                            </div>

                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={idx}
                                name={"primary_reason_visit" + idx}
                                value={99}
                                checked={data.primary_reason_visit=== 99}  
                                onChange={HandleRepeaterFieldChange}
                              />
                            
                                <span className="radio-text-padding">Unknown </span>
                              </label>
                            </div>
                          </div>

                          <ErrorField show={data.primary_reasonUnksoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={data.primary_reasonEmptyHard} message={HRU_MSGS.primary_reasonEmptyHard} />
                          <ErrorField show={data.primary_reasontxtEmptyHard} message={"Please enter the text."} />

                        </div>
                      </div> )))
}
{erVisitsDetails && erVisitsDetails.length < 100 &&
                      <div className="sub-question-bot-sapce-ul mt-2">
                        <div className="answer-list-text" onClick={AddERVisitRow}>
                            <a>
                              <img width="18px" src="../Assets/images/plus.png" alt="" />
                            </a>
                            <a className="radio-text-padding">
                              Click here to add another ER Visit
                            </a>
                        </div>
                      </div>
                    }
                    </div>

                          }
                    {/* Q103-104 Set2 end */}

                    <div className="vertical-pad-15">
                      <span className="text-spacing">
                        <b>Outpatient visits{" "}</b>
                      </span>
                    </div>
                    <div className="question-bot-sapce" style={{paddingBottom:"10px"}}>
                      <div className="question question-weight">
                        <span>Q96.</span>
                        <span className="quest-text-pad">
                        Does the patient have any outpatient visits related to LBCL? {""}

                        </span>
                      </div>
                      <div className="double-dig-answer">
                      <div className="">
                      <label>
                          <input
                            type="radio"
                            id="q96Unk"
                            name="q96_outpatient_visits_Unk"
                            value={1}
                          checked={
                            hruDetails.q96_outpatient_visits_Unk === 1
                          }
                          onChange={HandleFieldChange} 
                          />
                          
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q96Unk"
                            name="q96_outpatient_visits_Unk"
                            value={0}
                          checked={
                            hruDetails.q96_outpatient_visits_Unk === 0
                          }
                          onChange={HandleFieldChange} 
                          />
                          
                            <span className="radio-text-padding">
                              No
                            </span>
                          </label>
                        </div>

                        <div className="option-without-input">
                          <input type="radio"
                            id="q96Unk"
                            name="q96_outpatient_visits_Unk"
                            value={99}
                          checked={
                            hruDetails.q96_outpatient_visits_Unk === 99
                          }
                          onChange={HandleFieldChange} 
                          />
                          <label htmlFor="q96Unk">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q96LbclEmpty} message={HRU_MSGS.q96Outpatientvisits} />
                      {/* <ErrorField show={hardErrList.q96LbclTxtEmpty} message={HRU_MSGS.q96OutpatientvisitsTxtEmpty} /> */}
                      <ErrorField show={softErrList.q96LbclUnk_err} message={CommonMsgs.Unknown} />
                      {/* <ErrorField show={hardErrList.q96SizeRangeLimHard} message={HRU_MSGS.RangeLimit} /> */}
                    </div>


                    {/* Q105-107 Set1 start */}

                    {hruDetails.q96_outpatient_visits_Unk === 1 && 
                    <div className="dependent-section">
                      {
                      outpatientVisit && outpatientVisit.length > 0 && (
                        outpatientVisit.map((test, idx) => (
                                            
                      <div className="loop-section-ul">
                        <div className="loop-head">
                          <span className="question-weight">{GetOrdinal(idx + 1)} OP Visit</span>
                          {idx != 0 && <div className="delete-btn-div">
                            <img
                             width="16px"
                             src="../Assets/images/Icon-material-delete.png"
                            alt=""
                            onClick={() => DeleteOutpatientRow(idx)}
                            />
                            </div>}
                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q105.</span>
                            <span className="quest-text-pad">
                              Outpatient visit date
                            </span>
                          </div>
                          <div className="double-dig-answer">

                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id={idx}
                                name={"outpatient_assess_dx" + idx}
                                value={1}
                                checked={test.outpatient_assess_dx === 1}
                                onChange={HandleRepeaterFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                hideUnk = {true}
                                ctrlId={"outpatient_assess_dx_date-" + idx}
                                  HandleDateChange={HandleRepeaterDateChange}
                                date={test.outpatient_assess_dx_date}
                                />
                              </span>
                            </div>
                            <div className="">
                            <label>
                              <input
                                type="radio"
                                id={idx}
                                name={"outpatient_assess_dx" + idx}
                                value={99}
                               checked={  test.outpatient_assess_dx === 99}
                               onChange={HandleRepeaterFieldChange}
                              />
                              
                                <span className="radio-text-padding">Unknown </span>
                              </label>
                            </div>
                          </div>


                          <ErrorField show={test.outpatientDxDateUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={test.outpatientDxEmptyHard} message={HRU_MSGS.outpatientvisitDateEmpty} />

                          <ErrorField show={test.outpatientLbclEmptyHard} message={HRU_MSGS.outpatientLbcl} />
                          <ErrorField show={test.outpatientAfter_dt_Ca_EmptyHard} message={HRU_MSGS.outpatientvisitafterdtca} />

                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q106.</span>
                            <span className="quest-text-pad">
                              Type of healthcare professional visited
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={1}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 1}
                            
                              />
                              <label htmlFor="q106_1">
                                <span className="radio-text-padding">
                                  Oncologist
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={2}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 2}
                              />
                              <label htmlFor="q106_2">
                                <span className="radio-text-padding">
                                  Hematologist
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={3}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 3}
                              />
                              <label htmlFor="q106_3">
                                <span className="radio-text-padding">
                                  Radiologist
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={4}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 4}
                              />
                              <label htmlFor="q106_4">
                                <span className="radio-text-padding">
                                  Nurse/Specialized nurse
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={5}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 5}
                              />
                              <label htmlFor="q106_5">
                                <span className="radio-text-padding">
                                  Community/District nurse
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={6}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 6}
                              />
                              <label htmlFor="q106_6">
                                <span className="radio-text-padding">
                                  Primary care provider
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={7}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 7}
                              />
                              <label>
                                <span className="radio-text-padding">
                                  Palliative care specialist
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={8}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 8}
                              />
                              <label htmlFor="q106_8">
                                <span className="radio-text-padding">
                                  Other, specify:{" "}
                                  <input
                                    className="input-dash"
                                     name={"healthcareOthTxt"}
                                     id={idx}
                                    type="text"
                                    maxLength={100}
                                   value={test.healthcareOthTxt}
                                   onChange={HandleRepeaterFieldChange}
                                  />
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"healthcare_assess" + idx}
                                value={99}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.healthcare_assess === 99}
                              />
                              <label htmlFor="q106_99">
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>

                          <ErrorField show={test.healthcareUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={test.healthcareEmptyHard} message={HRU_MSGS.healthcareEmptyHard} />
                          <ErrorField show={test.healthcareOthTxtEmptyHard} message={"Please enter the text."} />





                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q107.</span>
                            <span className="quest-text-pad">
                              Primary reason for visit
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"visit_reason" + idx}
                                value={1}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.visit_reason === 1}
                              />
                              <label >
                                <span className="radio-text-padding">
                                  LBCL symptoms
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"visit_reason" + idx}
                                value={2}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.visit_reason === 2}
                              />
                              <label >
                                <span className="radio-text-padding">
                                  Disease progression
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"visit_reason" + idx}
                                value={3}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.visit_reason === 3}
                              />
                              <label>
                                <span className="radio-text-padding">
                                  LBCL-related routine follow-up
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"visit_reason" + idx}
                                value={4}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.visit_reason === 4}
                              />
                              <label >
                                <span className="radio-text-padding">
                                  Other, specify:{" "}
                                  <input
                                    className="input-dash"
                                     name={"visitreasonOthTxt"}
                                    id={idx}
                                    type="text"
                                    maxLength={100}
                                  value={test.visitreasonOthTxt}
                                   onChange={HandleRepeaterFieldChange}
                                  />
                                </span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={idx}
                                name={"visit_reason" + idx}
                                value={99}
                                onChange={HandleRepeaterFieldChange}
                                checked={test.visit_reason === 99}
                              />
                              <label htmlFor="q107_99">
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>

                          <ErrorField show={test.visitreasonUnkSoft} message={CommonError_Msgs.UnknownSelect} />
                          <ErrorField show={test.visitreasonEmptyHard} message={HRU_MSGS.visitreasonEmptyHard} />
                          <ErrorField show={test.visitreasonOthTxtEmptyHard} message={"Please enter the text."} />


                        </div>
                      </div>
                      )))
                            }

{outpatientVisit && outpatientVisit.length < 100 &&
                      <div className="sub-question-bot-sapce-ul mt-2">
                        <div className="answer-list-text" onClick={AddOutpatientRow}>
                            <a>
                              <img width="18px" src="../Assets/images/plus.png" alt="" />
                            </a>
                            <a className="radio-text-padding">
                              Click here to add another ER Visit
                            </a>
                        </div>
                      </div>
                    }

                    </div>
                    }

                    <div className="vertical-pad-15">
                      <span className="text-spacing">
                        <b>Lab tests{" "}</b>
                      </span>
                    </div>
                    
                    <div className="question-bot-sapce" style={{paddingBottom:"10px"}}>
                      <div className="question question-weight">
                        <span>Q97.</span>
                        <span className="quest-text-pad">
                        Does the patient undergo renal function tests? {""}
                      
                        </span>
                      </div>
                      <div className="double-dig-answer">

                      <div className="">
                      <label>
                          <input
                            type="radio"
                            id="q97Unk"
                            name="q97_renal_tests_Unk"
                            value={1}
                          checked={
                            hruDetails.q97_renal_tests_Unk === 1
                          }
                          onChange={HandleFieldChange} 
                          />
                          
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q97Unk"
                            name="q97_renal_tests_Unk"
                            value={0}
                          checked={
                            hruDetails.q97_renal_tests_Unk === 0
                          }
                          onChange={HandleFieldChange} 
                          />
                          
                            <span className="radio-text-padding">
                              No
                            </span>
                          </label>
                        </div>

                        <div className="option-without-input">
                        <label >
                          <input type="radio"
                            id="q97Unk"
                            name="q97_renal_tests_Unk"
                            value={99}
                          checked={
                            hruDetails.q97_renal_tests_Unk === 99
                          }
                          onChange={HandleFieldChange} 
                          />
                         
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q97renalEmpty} message={HRU_MSGS.q97LabTests} />
                      {/* <ErrorField show={hardErrList.q97renalTxtEmpty} message={HRU_MSGS.q97LabTestsTxtEmpty} /> */}
                      <ErrorField show={softErrList.q97renalUnk_err} message={CommonMsgs.Unknown} />
                      {/* <ErrorField show={hardErrList.q97SizeRangeLimHard} message={HRU_MSGS.RangeLimit} /> */}
                    </div>
                    {/* Q108 Set1 start */}

                    {hruDetails.q97_renal_tests_Unk === 1 &&
                      <div className="dependent-section">
    {
                     renalTests  && renalTests.length > 0 && (
                      renalTests.map((test, index) => (
                    
                        <div className="loop-section-ul">
                        <div className="loop-head">
                          <span className="question-weight">{GetOrdinal(index + 1)} Renal Function Test</span>
                          {index != 0 && <div className="delete-btn-div">
                            <img
                             width="16px"
                             src="../Assets/images/Icon-material-delete.png"
                            alt=""
                            onClick={() => DeleteRenalRow(index)}
                            />
                            </div>}
                        </div>
                        <div className="sub-question-bot-sapce answer-pad-left">
                          <div className="question question-weight">
                            <span>Q108.</span>
                            <span className="quest-text-pad">
                              Renal function test date
                            </span>
                          </div>
                          <div className="double-dig-answer">

                            <div className="date-field-padding">
                              <input
                                type="radio"
                                id={index}
                                name={"renal_assess_dx" + index}
                                value={1}
                                checked={test.renal_assess_dx === 1}
                                onChange={HandleRepeaterFieldChange}
                              />
                              <span className="radio-text-padding">
                                <DateControl
                                hideUnk = {true}
                                ctrlId={"renal_dx_date-" + index}
                                  HandleDateChange={  HandleRepeaterDateChange}
                                  date={test.renal_assess_dx_date}
                                  removeErrMsgPadding={true}
                                />
                              </span>
                            </div>
                            <div className="">
                            <label >
                              <input
                                type="radio"
                                id={index}
                                name={"renal_assess_dx" + index}
                                value={99}
                                checked={test.renal_assess_dx === 99 }
                                onChange={HandleRepeaterFieldChange}
                              />
                              
                              <span className="radio-text-padding">Unknown </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                                show={test.renalDxDateUnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />



                              <ErrorField
                                show={test.renalDxEmptyHard}
                                message={HRU_MSGS.renalEmpty}
                              />

                              <ErrorField
                                show={test.renalLbclEmptyHard}
                                message={HRU_MSGS.OnAfterRenal_index_dt}
                              />
                              <ErrorField
                                show={test.renalAfter_dt_Ca_EmptyHard}
                                message={HRU_MSGS.BeforeRenal_death_dt}
                              />


                        </div>
                      </div>
                       )))
                       }

{renalTests && renalTests.length < 100 &&
                      <div className="sub-question-bot-sapce-ul mt-2">
                        <div className="answer-list-text" onClick={AddRenalRow}>
                            <a>
                              <img width="18px" src="../Assets/images/plus.png" alt="" />
                            </a>
                            <a className="radio-text-padding">
                              Click here to add another Renal function test
                            </a>
                        </div>
                      </div>
                    }
                    

                    </div>
                    }


                    <div className="question-bot-sapce" style={{paddingBottom:"10px"}}>
                      <div className="question question-weight">
                        <span>Q98.</span>
                        <span className="quest-text-pad">
                        Does the patient undergo liver function tests? {""}

                        </span>
                      </div>
                      <div className="double-dig-answer">

                      <div className="">
                      <label>
                          <input
                            type="radio"
                            id="q98Unk"
                            name="q98_liver_tests_Unk"
                            value={1}
                          checked={
                            hruDetails.q98_liver_tests_Unk === 1
                          }
                          onChange={HandleFieldChange} 
                          />
                          
                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="">
                        <label>
                          <input
                            type="radio"
                            id="q98Unk"
                            name="q98_liver_tests_Unk"
                            value={0}
                          checked={
                            hruDetails.q98_liver_tests_Unk === 0
                          }
                          onChange={HandleFieldChange} 
                          />
                          
                            <span className="radio-text-padding">
                              No
                            </span>
                          </label>
                        </div>



                        <div className="option-without-input">
                          <input type="radio"
                            id="q98Unk"
                            name="q98_liver_tests_Unk"
                            value={99}
                          checked={
                            hruDetails.q98_liver_tests_Unk === 99
                          }
                          onChange={HandleFieldChange} 
                          />
                          <label htmlFor="q98Unk">
                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField show={hardErrList.q98liverEmpty} message={HRU_MSGS.q98liverEmpty} />
                      {/* <ErrorField show={hardErrList.q98liverTxtEmpty} message={HRU_MSGS.q98liverTxtEmpty} /> */}
                      <ErrorField show={softErrList.q98liverUnk_err} message={CommonMsgs.Unknown} />
                      {/* <ErrorField show={hardErrList.q98SizeRangeLimHard} message={HRU_MSGS.RangeLimit} /> */}
                    </div>



                {/* Repeater section for 98 */}
                {hruDetails.q98_liver_tests_Unk === 1 &&
                        <div className="dependent-section">

{
                      liverTests && liverTests.length > 0 && (
                        liverTests.map((test, index) => (
                    
                            <div className="loop-section-ul">
                              <div className="loop-head">
                                <span className="question-weight">{GetOrdinal(index + 1)} Liver Function Test</span>
                                {index != 0 && <div className="delete-btn-div">
                            <img
                             width="16px"
                             src="../Assets/images/Icon-material-delete.png"
                            alt=""
                            onClick={() => DeleteLiverRow(index)}
                            />
                            </div>}
                              
                              </div>
                              <div className="sub-question-bot-space answer-pad-left">
                                <div className="question question-weight">
                                  <span>Q109.</span>
                                  <span className="quest-text-pad">Liver function test date</span>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="date-field-padding">
                                    <input
                                      type="radio"
                                      // id={`q109_${index}_known`}
                                      id={index}
                                      // checked={test.liverFunctionTestDateUnk }
                                    name={"liver_assess_dx" + index}
                                    value={1}
                                    checked={test.liver_assess_dx === 1}
                                    onChange={HandleRepeaterFieldChange}

                                    />
                                    <span className="radio-text-padding">
                                      <DateControl
                                        hideUnk = {true}
                                        ctrlId={"liver_dx_date-" + index}
                                        HandleDateChange={
                                        HandleRepeaterDateChange
                                        }
                                        date={test.liver_assess_dx_date}
                                        removeErrMsgPadding={true}
                                      />
                                    </span>
                                  </div>
                                  <div>
                                  <label >
                                    <input
                                      type="radio"
                                      // id={`q109_${index}_unknown`}
                                      id={index}
                                      name={"liver_assess_dx" + index}
                                      value={99}
                                      checked={
                                        test.liver_assess_dx === 99
                                      }
                                      onChange={HandleRepeaterFieldChange}
                                      // name={`q109_${index}`}
                                      // checked={test.liverFunctionTestDateUnk}
                                      // onChange={() => handleUnknownChange(index, true)}
                                    />
                                
                                      <span className="radio-text-padding">Unknown</span>
                                    </label>
                                  </div>
                                </div>
                              <ErrorField
                                show={test.liverDxDateUnkSoft}
                                message={CommonError_Msgs.UnknownSelect}
                              />



                              <ErrorField
                                show={test.liverDxEmptyHard}
                                message={HRU_MSGS.LiverEmpty}
                              />

                              <ErrorField
                                show={test.liverLbclEmptyHard}
                                message={HRU_MSGS.OnAfter_index_dt}
                              />
                              <ErrorField
                                show={test.liverAfter_dt_Ca_EmptyHard}
                                message={HRU_MSGS.Before_death_dt}
                              />



              </div>
            </div>
          )))
          }
                 {liverTests && liverTests.length < 100 &&
                      <div className="sub-question-bot-sapce-ul mt-2">
                        <div className="answer-list-text" onClick={AddLiverRow}>
                            <a>
                              <img width="18px" src="../Assets/images/plus.png" alt="" />
                            </a>
                            <a className="radio-text-padding">
                              Click here to add another Liver function test
                            </a>
                        </div>
                      </div>
                    }
                      
                 
                    </div>
                    }
                    {/* <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q110.</span>
                        <span className="quest-text-pad">
                          Do you confirm that you have reviewed the abstracted data for
                          this chart and agree with the information provided?
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="">
                          <input
                            type="radio"
                            id="q110"
                            name="q110"
                            value={1}
                          // checked={ptDetails.q14Comorb_none}
                          // onChange={HandleFieldChange}
                          />
                          <label htmlFor="q110">
                            <span className="radio-text-padding">Yes </span>
                          </label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HealthcareResourceUtilization;
